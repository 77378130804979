import { h, Component } from 'preact';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import { link, primaryInverse } from '../../shared-styles/buttons.css';
import { list } from '../../shared-styles/list.css';
import TemplateForm from '../form';
import TemplateList from '../list';
import { GET_CAMPAIGN } from '../../../queries/campaign';
import { TEMPLATE_FIELDS } from '../../../queries/template';
import { empty, templateContainer } from './style.css';
import { parse } from 'papaparse';
import { baseStaticUrl } from '../../../shared/constants';

const phojiRegExp = /(##[a-zA-Z0-9\s\-_.]+##)/g;

const ADD_TEMPLATE_TO_CAMPAIGN = gql`
  mutation AddTemplateToCampaign(
    $body: String!
    $campaignId: String!
    $messageDataFile: String
    $name: String!
  ) {
    addTemplateToCampaign(
      body: $body
      campaignId: $campaignId
      messageDataFile: $messageDataFile
      name: $name
    ) {
      ...templateFields
    }
  }
  ${TEMPLATE_FIELDS}
`;

const PRESIGN = gql`
  mutation PresignMessageDataUpload(
    $fileName: String!
    $fileType: String!
    $campaignId: String!
    $templateName: String!
  ) {
    presignMessageDataUpload(
      fileName: $fileName
      fileType: $fileType
      campaignId: $campaignId
      templateName: $templateName
    )
  }
`;

const REMOVE_TEMPLATE_FROM_CAMPAIGN = gql`
  mutation RemoveTemplateFromCampaign($campaignId: String!, $name: String!) {
    removeTemplateFromCampaign(campaignId: $campaignId, name: $name)
  }
`;

const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate(
    $campaignId: String!
    $name: String!
    $body: String!
    $newMessageDataFile: String
  ) {
    updateTemplate(
      campaignId: $campaignId
      name: $name
      body: $body
      newMessageDataFile: $newMessageDataFile
    ) {
      ...templateFields
    }
  }
  ${TEMPLATE_FIELDS}
`;

class TemplateEditor extends Component<any, any> {
  private addTemplateToCampaign = async template => {
    const {
      client: { mutate },
      campaign: { campaignId }
    } = this.props;

    await mutate({
      mutation: ADD_TEMPLATE_TO_CAMPAIGN,
      update: (store, { data: { addTemplateToCampaign } }) => {
        const { campaign } = store.readQuery({
          query: GET_CAMPAIGN,
          variables: { campaignId }
        });

        campaign.templates.push(addTemplateToCampaign);
      },
      variables: {
        campaignId,
        ...template,
        messageDataFile: template.newMessageDataFile
          ? template.newMessageDataFile.name
          : null
      }
    });
  };

  private removeTemplateFromCampaign = async template => {
    const {
      client: { mutate },
      campaign: { campaignId }
    } = this.props;

    await mutate({
      mutation: REMOVE_TEMPLATE_FROM_CAMPAIGN,
      update: store => {
        const { campaign } = store.readQuery({
          query: GET_CAMPAIGN,
          variables: { campaignId }
        });
        const index = campaign.templates.findIndex(
          t => t.name === template.name
        );
        campaign.templates.splice(index, 1);
      },
      variables: {
        campaignId,
        ...template
      }
    });
  };

  private uploadMessageDataFile = async ({
    newMessageDataFile: file,
    name
  }) => {
    const {
      client: { mutate },
      campaign: { campaignId }
    } = this.props;
    const {
      data: { presignMessageDataUpload }
    } = await mutate({
      mutation: PRESIGN,
      variables: {
        fileName: file.name,
        fileType: file.type,
        campaignId,
        name
      }
    });

    await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener('progress', ({ loaded, total }) =>
        this.setState({
          percentUploaded: Math.floor((100 * loaded) / total)
        })
      );

      xhr.upload.addEventListener('error', e => {
        this.setState({ percentUploaded: 100 });
        reject(e);
      });

      xhr.upload.addEventListener('load', e => {
        this.setState({ percentUploaded: 100 });
        resolve(e);
      });

      xhr.open('PUT', presignMessageDataUpload);
      xhr.send(file);
    });
  };

  private updateTemplate = async template => {
    const {
      client: { mutate },
      campaign: { campaignId }
    } = this.props;

    await mutate({
      mutation: UPDATE_TEMPLATE,
      update: (store, { data: { updateTemplate } }) => {
        const { campaign } = store.readQuery({
          query: GET_CAMPAIGN,
          variables: { campaignId }
        });

        const index = campaign.templates.findIndex(
          t => t.name === template.name
        );
        campaign.templates.splice(index, 1, updateTemplate);
      },
      variables: {
        campaignId,
        ...template,
        newMessageDataFile: template.newMessageDataFile
          ? template.newMessageDataFile.name
          : null
      }
    });
  };

  private initialState = () => {
    return {
      percentUploaded: null,
      selectedTemplate: null
    };
  };

  public render(
    { campaign: { campaignId, templates } },
    { addingTemplate, percentUploaded, selectedTemplate }
  ) {
    return selectedTemplate ? (
      <div class={templateContainer}>
        <section>
          <h3>Edit Template</h3>
          <TemplateForm
            onCancel={() => this.setState(this.initialState())}
            onDelete={async template => {
              await this.removeTemplateFromCampaign(template);
              this.setState(this.initialState());
            }}
            onSubmit={async template => {
              if (template.newMessageDataFile) {
                await this.uploadMessageDataFile(template);
              }
              await this.updateTemplate(template);
              this.setState(this.initialState());
            }}
            percentUploaded={percentUploaded}
            template={selectedTemplate}
          />
        </section>
        {selectedTemplate.dataPreview && (
          <section>
            <h3>Preview</h3>
            <ul class={`${list} animated fadeIn`}>
              {parse(selectedTemplate.dataPreview, { header: true })
                .data.slice(0, 10)
                .map(line => {
                  const body = selectedTemplate.body.replace(
                    /\{\{[a-zA-Z0-9\s\.]*\}\}/g,
                    m => {
                      const tag = m.substring(2, m.length - 2);
                      return line[tag] === undefined ? '' : line[tag];
                    }
                  );

                  return (
                    <li
                      style={{
                        'font-size': '16px'
                      }}
                    >
                      {body.split(phojiRegExp).map((s, i) => {
                        if (i % 2 === 0) {
                          return s;
                        }
                        const tag = s.substring(2, s.length - 2);
                        return (
                          <img
                            style={{
                              'vertical-align': 'bottom'
                            }}
                            src={`${baseStaticUrl}/${campaignId}/${tag}?s=${22}`}
                          />
                        );
                      })}
                    </li>
                  );
                })}
            </ul>
          </section>
        )}
      </div>
    ) : addingTemplate ? (
      <div>
        <TemplateForm
          onCancel={() => this.setState({ addingTemplate: false })}
          onSubmit={async template => {
            if (template.newMessageDataFile) {
              await this.uploadMessageDataFile(template);
            }
            await this.addTemplateToCampaign(template);
            this.setState({ addingTemplate: false });
          }}
          percentUploaded={percentUploaded}
        />
      </div>
    ) : (templates || []).length === 0 ? (
      <div class={empty}>
        <em>
          No templates created yet.&nbsp;
          <button
            class={link}
            onClick={() =>
              this.setState({
                addingTemplate: true
              })
            }
          >
            Create a new template
          </button>
        </em>
      </div>
    ) : (
      <div>
        <TemplateList
          onDelete={async (template): Promise<void> => {
            await this.removeTemplateFromCampaign(template);
          }}
          templates={templates}
          templateSelected={t =>
            this.setState({
              percentUploaded: null,
              selectedTemplate: t,
              selectedtemplateName: t.name
            })
          }
        />
        <button
          class={primaryInverse}
          onClick={() =>
            this.setState({
              addingTemplate: true
            })
          }
        >
          Create a new template
        </button>
      </div>
    );
  }
}

export default withApollo(TemplateEditor);
