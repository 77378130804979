import { h, Component } from 'preact';
import { Query, withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import Alert, { Type } from '../../alert';
import UploadButton from '../../upload-button';

import { baseStaticUrl } from '../../../shared/constants';
import { primaryInverse } from '../../shared-styles/buttons.css';
import style from './style.css';

const GET_CAMPAIGN_PHOJIS = gql`
  query PhojiQuery($campaignId: String!, $cursor: String) {
    campaignPhojis(campaignId: $campaignId, cursor: $cursor) {
      page {
        name
      }
      cursor
    }
  }
`;

const PRESIGN = gql`
  mutation PresignPhojiUpload(
    $fileName: String!
    $fileType: String!
    $campaignId: String!
  ) {
    presignCampaignPhojiUpload(
      fileName: $fileName
      fileType: $fileType
      campaignId: $campaignId
    ) {
      url
      expectedPhoji {
        name
      }
    }
  }
`;

function addNewPhojiToCache(campaignId, phoji, client) {
  const { phojis } = client.readQuery({
    query: GET_CAMPAIGN_PHOJIS,
    variables: { campaignId }
  });

  client.writeQuery({
    data: {
      phojis: {
        ...phojis,
        page: [phoji, ...phojis.page]
      }
    },
    query: GET_CAMPAIGN_PHOJIS,
    variables: { campaignId }
  });
}

class PhojiEditor extends Component {
  private uploadPhoji = async (file, url) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener('progress', ({ loaded, total }) =>
        this.setState({ percentUploaded: (100 * loaded) / total })
      );

      xhr.addEventListener('readystatechange', e => {
        if (xhr.readyState === 4) {
          this.setState({ percentUploaded: 100 });
          xhr.status === 200 ? resolve(e) : reject(e);
        }
      });
      xhr.open('PUT', url);
      xhr.send(file);
    });

  private upload = async ({ target }) => {
    const q = Array.from(target.files).reverse();
    const totalFiles = q.length;

    const {
      client,
      client: { mutate },
      campaign: { campaignId }
    } = this.props;

    while (q.length) {
      const file = q.pop();

      this.setState({
        percentUploaded: 0,
        numberOfFilesLeft: q.length + 1,
        totalFiles,
        uploadInProgress: true
      });

      const {
        data: { presignPhojiUpload }
      } = await mutate({
        mutation: PRESIGN,
        variables: {
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
          campaignId
        }
      });

      const { url, expectedPhoji: phoji } = presignPhojiUpload;
      await this.uploadPhoji(file, url);
      addNewPhojiToCache(campaignId, phoji, client);
    }

    this.setState({ uploadInProgress: false });
  };

  private loadMore = ({ cursor, fetchMore }) => {
    fetchMore({
      variables: {
        cursor
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        fetchMoreResult.phojis.page = [
          ...prev.phojis.page,
          ...fetchMoreResult.phojis.page
        ];

        return fetchMoreResult;
      }
    });
  };

  public render(
    { campaign: { campaignId } },
    { numberOfFilesLeft, percentUploaded, uploadInProgress }
  ) {
    return (
      <Query query={GET_CAMPAIGN_PHOJIS} variables={{ campaignId }}>
        {({ data, error, fetchMore, loading }) => {
          if (loading) {
            return <div>Loading</div>;
          }
          if (error) {
            return <Alert type={Type.Error}>{error.message}</Alert>;
          }
          const {
            phojis: { cursor, page: phojis }
          } = data;

          return (
            <div>
              <UploadButton
                accept="image/*"
                loading={uploadInProgress}
                multiple={true}
                onChange={e => this.upload(e)}
                title="Upload images"
                tooltip="Upload phoji images"
              />
              <ul class={style.phojis}>
                {uploadInProgress &&
                  Array.from({ length: numberOfFilesLeft }).map((_, i) => {
                    const isLast = i === numberOfFilesLeft - 1;
                    const clip = isLast ? `${100 - percentUploaded}px` : 0;
                    return (
                      <li class="animated fadeIn">
                        <svg
                          style={{ 'clip-path': `inset(0 0 0 ${clip})` }}
                          class={style.placeholder}
                        />
                      </li>
                    );
                  })}
                {phojis &&
                  phojis.map(({ name }) => (
                    <li class="animated fadeIn">
                      <img
                        class={style.placeholder}
                        draggable={true}
                        onDragStart={e => {
                          if (!e.dataTransfer || !e.target) {
                            return;
                          }
                          const src =
                            (e.target as HTMLInputElement).getAttribute(
                              'src'
                            ) || '';
                          const path = new URL(src).pathname;
                          const filename = path.split('/').pop();
                          e.dataTransfer.dropEffect = 'copy';
                          e.dataTransfer.setData(
                            'text',
                            ` ##${filename || ''}##`
                          );
                        }}
                        src={`${baseStaticUrl}/${name}?s=100`}
                      />
                    </li>
                  ))}
              </ul>
              {cursor && (
                <button
                  class={primaryInverse}
                  onClick={() => this.loadMore({ cursor, fetchMore })}
                >
                  Load more
                </button>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(PhojiEditor);
