import { h } from 'preact';
import { Link } from 'preact-router';
import {
  active,
  campaignName,
  container,
  dateContainer,
  inactive,
  statusContainer
} from './style.css';
import { CampaignStatus } from '../../../model/campaign';
import dateFormatter from '../../../shared/date-formatter';

export enum Type {
  Alert = 'alert',
  Error = 'error',
  Info = 'info',
  Warning = 'warning'
}

interface Props {
  createdAt: string;
  name: string;
  onClick: () => void;
  status: CampaignStatus;
  updatedAt: string;
}

export default ({
  createdAt,
  name,
  onClick,
  status,
  updatedAt
}: Props): JSX.Element => (
  <button onClick={onClick} class={container}>
    <div class={campaignName}>{name}</div>
    <div class={dateContainer}>
      <span>Created: {dateFormatter.format(new Date(createdAt))}</span>
      <span>Updated: {dateFormatter.format(new Date(updatedAt))}</span>
    </div>
    <div
      class={`${statusContainer} ${
        status == CampaignStatus.Archived ? inactive : active
      }`}
    >
      {status == CampaignStatus.Archived ? 'Archived' : 'Active'}
    </div>
  </button>
);
