import { h } from 'preact';
import style from './style.css';

export enum Type {
  Alert = 'alert',
  Error = 'error',
  Info = 'info',
  Warning = 'warning'
}

interface Props {
  children: Element | string;
  error?: Error;
  type: Type;
}

export default ({ children, error, type }: Props): JSX.Element => {
  const detailsMap = error
    ? [
        ['Name', error.name],
        ['Message', error.message],
        ['Number', error['number']],
        ['Stack', error.stack],
        ['File name', error['fileName']],
        ['Line number', error['lineNumber']],
        ['Column number', error['columnNumber']]
      ]
    : null;
  return (
    <div class={`${style.alert} ${style[type]}`}>
      <div class="message">{children}</div>
      {detailsMap && (
        <div class={style.details}>
          {
            <div>
              More Details:
              <pre>
                <ul>
                  {detailsMap
                    .filter(([_, value]) => value)
                    .map(([name, value]) => (
                      <li>
                        <b>{name}:</b> {value}
                      </li>
                    ))}
                </ul>
              </pre>
            </div>
          }
        </div>
      )}
    </div>
  );
};
