import { h } from 'preact';

import style from './style.css';
import { PreviewData } from '../../../shared/message-data';

export default ({ data, fields }: PreviewData): JSX.Element => {
  return (
    <table class={style.preview}>
      <tr>
        {fields.map(f => (
          <th>{f}</th>
        ))}
      </tr>
      {data.map(lineObject => (
        <tr>
          {fields.map(f => (
            <td>{lineObject[f]}</td>
          ))}
        </tr>
      ))}
    </table>
  );
};
