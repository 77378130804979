import { h, Component } from 'preact';

import { formFull, formGroup, required } from '../../shared-styles/form.css';
import {
  destroy,
  primary,
  primaryInverse
} from '../../shared-styles/buttons.css';
import Progress from '../../../components/progress';
import UploadButton from '../../upload-button';
import MessageDataFileLink from '../message-data-file-link';
import { overlay, uploadStatus } from './style.css';
import { TemplateType } from '../../../shared/constants';

interface State {
  body: string;
  errorMessage: string;
  id: string;
  newMessageDataFile?: File;
}

interface Props {
  onCancel: () => void;
  onDelete?: (state: State) => void;
  onSubmit: (template: any) => Promise<void>;
  percentUploaded?: number;
  template?: any;
}

export default class TemplateForm extends Component<Props, State> {
  private input: any;
  public constructor(props) {
    super(props);
    this.state = props.template;
  }

  public componentDidMount() {
    this.input.focus();
  }

  public render(
    { onCancel, onDelete, onSubmit, percentUploaded, template },
    { errorMessage, newMessageDataFile }
  ) {
    const { body, dataFile } = template || {};
    return (
      <form
        class={formFull}
        method="post"
        onSubmit={e => {
          e.preventDefault();
          onSubmit(this.state);
        }}
      >
        {percentUploaded && (
          <div class={overlay}>
            <div>
              <div class={uploadStatus}>
                Uploading data file: {percentUploaded}%
              </div>
              <Progress value={percentUploaded} />
            </div>
          </div>
        )}
        <div class={formGroup}>
          <label for="id">ID</label>
          <input
            autofocus
            id="name"
            disabled={!!template}
            name="name"
            onChange={e =>
              this.setState({
                name: (e.target as HTMLInputElement).value
              })
            }
            placeholder="This value is used to match message data to templates"
            ref={c => (this.input = c)}
            required
            type="text"
            value={this.state.name}
          />
        </div>
        <div class={formGroup}>
          <label class={required}>
            <span>Message Template Type</span>
            <select
              onChange={({ target }) =>
                this.setState({
                  type: (target as HTMLSelectElement).value
                })
              }
              name="type"
              placeholder="Select One"
              required
            >
              <option value="">Select One</option>
              <option value={TemplateType.Email}>Email</option>
              <option value={TemplateType.Mobile}>
                Mobile push notification
              </option>
              <option value={TemplateType.Web}>Web message</option>
            </select>
          </label>
        </div>
        <UploadButton
          accept=".csv"
          multiple={false}
          onChange={e =>
            this.setState({
              newMessageDataFile: ((e.target as HTMLInputElement).files ||
                [])[0]
            })
          }
          title={`${dataFile ? 'Replace' : 'Upload'} bulk message data`}
          tooltip="Upload bulk message"
        />
        {newMessageDataFile && dataFile && (
          <span>
            &nbsp;New data file <b>{newMessageDataFile.name}</b> will&nbsp;
            <b>replace existing</b> data file{' '}
            <MessageDataFileLink dataFile={dataFile} />
          </span>
        )}
        {newMessageDataFile && !dataFile && (
          <span>
            &nbsp;<b>New data file:</b>&nbsp;
            {newMessageDataFile && newMessageDataFile.name}
          </span>
        )}
        {dataFile && !newMessageDataFile && (
          <span>
            &nbsp;<b>Existing data file:</b>&nbsp;
            <MessageDataFileLink dataFile={dataFile} />
          </span>
        )}
        <div class={formGroup}>
          <label for="body">Body</label>
          <textarea
            id="body"
            name="body"
            onChange={e =>
              this.setState({
                body: (e.target as HTMLInputElement).value
              })
            }
            onDrop={e => {
              if (!e.dataTransfer) {
                return;
              }
              e.preventDefault();
              this.setState({
                body: `${body}${e.dataTransfer.getData('text')}`
              });
            }}
            placeholder="The body of your message. E.g., Hello {{name}} ##MyPhoji.png##"
            required
            style="width:100%; height: 200px;"
            value={this.state.body}
          />
        </div>
        {errorMessage && <div>{errorMessage}</div>}
        <button class={primary} type="submit">
          Save
        </button>
        <button class={primaryInverse} onClick={onCancel} type="button">
          Cancel
        </button>
        {onDelete && (
          <button
            class={destroy}
            onClick={() => onDelete(this.state)}
            type="button"
          >
            <i class="fas fa-trash" />
            &nbsp;Delete
          </button>
        )}
      </form>
    );
  }
}
