import { h, Component } from 'preact';
import { missing } from '../../shared-styles/general.css';
import {
  deleteableRow,
  fullWidth,
  noPadding,
  responsiveTable,
  table,
  tableLink
} from '../../shared-styles/table.css';

import MessageDataFileLink from '../message-data-file-link';

interface Props {
  onDelete: (template: any) => Promise<void>;
  templateSelected: (template: any) => void;
  templates: any[];
}

export default class TemplateList extends Component<Props, {}> {
  public render({ onDelete, templateSelected, templates }) {
    return (
      <div class={responsiveTable}>
        <table class={`${table} animated fadeIn`}>
          <tr>
            <th>ID</th>
            <th>Data</th>
            <th>Template</th>
          </tr>
          {(templates || []).map(t => (
            <tr class={deleteableRow} onClick={() => templateSelected(t)}>
              <td>{t.name}</td>
              <td
                class={t.dataFile ? noPadding : missing}
                onClick={e => e.stopPropagation()}
              >
                {t.dataFile ? (
                  <MessageDataFileLink
                    classes={tableLink}
                    dataFile={t.dataFile}
                  />
                ) : (
                  'Missing'
                )}
              </td>
              <td class={fullWidth}>
                {t.body}
                <button
                  onClick={e => {
                    e.stopPropagation();
                    onDelete(t);
                  }}
                >
                  <i class="fas fa-trash" />
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>
    );
  }
}
