import { tokenPayload } from './token';

interface CompanyUser {
  companyId: string;
  companyName: string;
  email: string;
  name: string;
  profileUrl?: string;
}

interface ConsumerUser {
  email: string;
  name: string;
  profileUrl?: string;
}

export function isCompanyUser(obj: any): obj is CompanyUser {
  return 'companyId' in obj;
}

export function isConsumerUser(obj: any): obj is ConsumerUser {
  return !isCompanyUser(obj) && 'email' in obj;
}

export default (): CompanyUser | ConsumerUser | null => {
  const payload = tokenPayload();
  if (!payload) {
    return null;
  }
  return payload;
};
