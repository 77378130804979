import { h, Component } from 'preact';
import { active, header, logo, rightButtons, userMenu } from './style.css';
import HelpButton from '../help-button';
import UserMenu from '../user-menu';
import Link from '../link';

import { getToken, deleteToken, isTokenExpired } from '../../shared/token';

import wordLogo from '../../assets/images/phoji-word-logo-white.png';
import getCurrentUser, {
  isCompanyUser,
  isConsumerUser
} from '../../shared/current-user';

interface State {
  token: string | null;
}

export default class Header extends Component<{}, State> {
  public constructor() {
    super();

    this.state = {
      token: getToken()
    };

    if (typeof window === 'undefined') {
      return;
    }
    addEventListener('tokenExpired', () => {
      this.setState({
        token: ''
      });
      //route('/login');
    });

    addEventListener('tokenCreated', () => {
      this.setState({
        token: getToken()
      });
    });
  }

  public render() {
    const isExpired = isTokenExpired();
    const currentUser = getCurrentUser();
    const companyUser = !isExpired && isCompanyUser(currentUser);
    const consumerUser = !isExpired && isConsumerUser(currentUser);

    return (
      <header class={header}>
        <img class={logo} src={wordLogo} />
        <nav>
          {companyUser && (
            <Link activeClassName={active} path="/library" href="/library">
              Library
            </Link>
          )}
          {companyUser && (
            <Link activeClassName={active} path="/messages" href="/messages">
              Messages
            </Link>
          )}
          {companyUser && (
            <Link activeClassName={active} path="/apps" href="/apps">
              Apps
            </Link>
          )}
          {companyUser && (
            <Link
              activeClassName={active}
              partial="/campaigns"
              href="/campaigns"
            >
              Campaigns
            </Link>
          )}
          {consumerUser && (
            <Link activeClassName={active} partial="/phojis" href="/phojis">
              Your Phojis
            </Link>
          )}
          {isExpired && (
            <Link activeClassName={active} href="/register">
              Register
            </Link>
          )}
          {isExpired && (
            <Link activeClassName={active} href="/login">
              Login
            </Link>
          )}
        </nav>
      </header>
    );
  }
}
