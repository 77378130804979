import { h } from 'preact';
import style from './style.css';

interface LinkProps {
  href: string;
}

interface ButtonProps {
  class: string;
  onClick: () => void;
}

export const CloseLink = ({ href }: LinkProps): JSX.Element => (
  <a href={href} class={style.close}>
    <i class="fas fa-times"></i>
  </a>
);

export const CloseButton = ({
  class: className,
  onClick
}: ButtonProps): JSX.Element => (
  <button
    class={`${style.close} ${style.closeButton} ${className || ''}`}
    onClick={onClick}
  >
    <i class="fas fa-times"></i>
  </button>
);
