import { h, Component } from 'preact';
import { Query, withApollo } from 'react-apollo';

import Alert, { Type } from '../../../components/alert';
import PhojiEditor from '../../../components/phojis/editor';
import TemplateEditor from '../../../components/templates/editor';
import ExecuteCampaign from '../../../components/campaigns/execute';
import { container } from '../../../components/shared-styles/container.css';
import { GET_CAMPAIGN } from '../../../queries/campaign';
import { newTemplatePath } from '../../../shared/paths';

import { campaignSection } from './style.css';
import { Link } from 'preact-router';

interface Props {
  campaignId: string;
}

class ShowCampaign extends Component<Props, {}> {
  public render({ campaignId }) {
    return (
      <div class={container}>
        <Query query={GET_CAMPAIGN} variables={{ campaignId }}>
          {({ loading, error, data }): JSX.Element | null => {
            if (loading) {
              return null;
            }
            if (error) {
              return <Alert type={Type.Error}>{error.message}</Alert>;
            }
            const { campaign } = data;
            document.title = `${campaign.name} - Phoji`;
            return (
              <div>
                <h1>{campaign.name}</h1>

                <Link href={`/campaigns/${campaign.campaignId}/images`}>
                  Images
                </Link>
                <br />
                <Link href={newTemplatePath(campaign.campaignId)}>
                  Templates
                </Link>
                <section class={campaignSection}>
                  <h1>Phojis</h1>
                  <PhojiEditor campaign={campaign} />
                </section>
                <section class={campaignSection}>
                  <h1>Templates</h1>
                  <TemplateEditor campaign={campaign} />
                </section>
                {(campaign.templates || []).length > 0 && (
                  <section class={campaignSection}>
                    <h1>Output</h1>
                    <ExecuteCampaign campaignId={campaign.campaignId} />
                  </section>
                )}
                {/*
                <section>
                  <h1>Message Data</h1>
                  <MessageDataEditor campaign={campaign} />
                </section>
                <section>
                  <h1>Preview</h1>
                  <Preview {...campaign} />
                </section>
                */}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withApollo(ShowCampaign);
