import { h, Component, ComponentChild } from 'preact';
import { route } from 'preact-router';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { container } from '../../components/shared-styles/container.css';
import {
  form,
  formGroup,
  radioContainer
} from '../../components/shared-styles/form.css';
import { primary } from '../../components/shared-styles/buttons.css';
import { setToken } from '../../shared/token';
import { hidden } from './style.css';
import getCurrentUser, {
  isCompanyUser,
  isConsumerUser
} from '../../shared/current-user';

const REGISTER = gql`
  mutation register($registration: Registration!) {
    register(registration: $registration) {
      token
    }
  }
`;

enum AccountType {
  Company = 'User',
  Individual = 'Consumer'
}

interface State {
  accountType: AccountType;
  company?: string;
  email: string;
  errorMessage: string;
  name: string;
  password: string;
}

export default class Register extends Component<{}, State> {
  private handleSubmit = (e, mutation): void => {
    e.preventDefault();
    mutation();
  };

  private confirm({ register }): void {
    setToken(register);
    const currentUser = getCurrentUser();
    const companyUser = isCompanyUser(currentUser);
    const consumerUser = isConsumerUser(currentUser);

    if (companyUser) {
      route('/campaigns');
      return;
    }
    if (consumerUser) {
      route('/phojis');
      return;
    }
    alert('Unable to determine user type');
  }

  private error(): void {
    this.setState({
      errorMessage: 'Registration failed'
    });
  }

  public constructor() {
    super();

    this.state = {
      accountType: AccountType.Individual,
      email: '',
      errorMessage: '',
      name: '',
      password: ''
    };
  }

  public render(): ComponentChild {
    const {
      accountType = AccountType.Individual,
      company,
      email,
      errorMessage,
      name,
      password
    } = this.state;
    return (
      <Mutation
        mutation={REGISTER}
        onCompleted={(data): void => this.confirm(data)}
        onError={(data): void => this.error(data)}
        variables={{
          registration: { accountType, company, email, name, password }
        }}
      >
        {(mutation: Mutation): ComponentChild => (
          <div class={container}>
            <form
              class={form}
              method="post"
              onSubmit={(e): void => this.handleSubmit(e, mutation)}
            >
              <h1>Register</h1>
              <div class={formGroup}>
                <label>Account Type</label>
                <div class={radioContainer}>
                  <input
                    autofocus
                    checked={this.state.accountType == AccountType.Individual}
                    id="individual"
                    name="accountType"
                    onChange={(): void =>
                      this.setState({
                        accountType: AccountType.Individual
                      })
                    }
                    type="radio"
                    value={AccountType.Individual}
                  />
                  <label for="individual">Individual</label>
                </div>

                <div class={radioContainer}>
                  <input
                    checked={this.state.accountType == AccountType.Company}
                    id="company"
                    name="accountType"
                    onChange={(): void =>
                      this.setState({
                        accountType: AccountType.Company
                      })
                    }
                    type="radio"
                    value={AccountType.Company}
                  />
                  <label for="company">Company</label>
                </div>
              </div>
              <div class={formGroup}>
                <label>
                  Name
                  <input
                    name="name"
                    onChange={(e): void =>
                      this.setState({
                        name: (e.target as HTMLInputElement).value
                      })
                    }
                    required
                    type="text"
                    value={this.state.name}
                  />
                </label>
              </div>
              <div
                class={`${formGroup} ${
                  this.state.accountType == AccountType.Individual ? hidden : ''
                }`}
              >
                <label>
                  Company
                  <input
                    name="company"
                    onChange={(e): void =>
                      this.setState({
                        company: (e.target as HTMLInputElement).value
                      })
                    }
                    required={this.state.accountType == AccountType.Company}
                    type="text"
                    value={this.state.company}
                  />
                </label>
              </div>
              <div class={formGroup}>
                <label>
                  Email
                  <input
                    name="email"
                    onChange={(e): void =>
                      this.setState({
                        email: (e.target as HTMLInputElement).value
                      })
                    }
                    required
                    type="email"
                    value={this.state.email}
                  />
                </label>
              </div>
              <div class={formGroup}>
                <label>
                  Password
                  <input
                    name="password"
                    onChange={(e): void =>
                      this.setState({
                        password: (e.target as HTMLInputElement).value
                      })
                    }
                    required
                    type="password"
                    value={this.state.password}
                  />
                </label>
              </div>
              {errorMessage && <div>{errorMessage}</div>}
              <button class={primary} type="submit">
                Register
              </button>
            </form>
          </div>
        )}
      </Mutation>
    );
  }
}
