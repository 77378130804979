import { h } from 'preact';
import { disabled, label, upload } from './style.css';

interface Props {
  accept: string;
  loading?: boolean;
  multiple: boolean;
  tooltip?: string;
  title: string;
  onChange: (files: File[]) => void;
}

const UploadButton = ({
  accept,
  loading,
  multiple,
  tooltip,
  title,
  onChange
}: Props) => (
  <label
    class={`${label} ${loading ? disabled : ''}`}
    title={tooltip || 'Upload files'}
  >
    <div>{loading ? 'Upload in progress' : title}</div>
    {loading ? <i class="fas fa-sync fa-spin" /> : ''}
    <input
      accept={accept}
      class={upload}
      disabled={loading}
      multiple={multiple}
      onChange={e => {
        const input = e.target as HTMLInputElement;
        if (input.files && input.files.length) {
          onChange(Array.from(input.files));
        }
      }}
      type="file"
    />
  </label>
);

export default UploadButton;
