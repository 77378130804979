import gql from 'graphql-tag';

export const CAMPAIGN_FIELDS = gql`
  fragment campaignFields on Campaign {
    campaignId
    createdAt
    labels
    name
    phojis(limit: 15) {
      page {
        name
      }
      cursor
    }
    status
    templates {
      body
      campaignId
      createdAt
      createdBy
      dataFile
      fontSize
      isOutputCurrent
      name
      outputUrl
      phojiPosition
      phojiSize
      processedAt
      type
      updatedAt
      updatedBy
    }
    updatedAt
  }
`;

export const GET_CAMPAIGN = gql`
  query CampaignQuery($campaignId: String!) {
    campaign(campaignId: $campaignId) {
      ...campaignFields
    }
  }
  ${CAMPAIGN_FIELDS}
`;
