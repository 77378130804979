import { Component, h } from 'preact';

import Progress from '../progress';

import style from './style.css';

interface Props {
  accept: string;
  multiple: boolean;
  onChange: (files: File[]) => void;
  percentComplete?: number;
  prompt?: string;
  promptLinkText?: string;
  uploadInProgress: boolean;
}

interface State {
  dragging: boolean;
}

export default class Upload extends Component<Props, State> {
  public render(
    {
      accept,
      multiple,
      onChange,
      percentComplete,
      prompt,
      promptLinkText,
      uploadInProgress
    }: Props,
    { dragging }: State
  ) {
    return (
      <label
        class={`${style.uploadArea} ${uploadInProgress ? style.disabled : ''} ${
          dragging && !uploadInProgress ? style.dragOver : ''
        }`}
        disabled={uploadInProgress}
        draggable={!uploadInProgress}
        onDragOver={e => {
          e.stopPropagation();
          e.preventDefault();
          this.setState({ dragging: true });
        }}
        onDragLeave={() => {
          this.setState({ dragging: false });
        }}
        onDrop={e => {
          e.stopPropagation();
          e.preventDefault();
          if (uploadInProgress) {
            return false;
          }

          const re = new RegExp(accept);
          const files = Array.from(
            (e.dataTransfer && e.dataTransfer.files) || []
          ).filter(f => f.type.match(re));
          if (files.length) {
            onChange(files);
          }
          this.setState({ dragging: false });
        }}
      >
        <div
          class={`${style.readyContainer} ${
            uploadInProgress ? style.disabled : ''
          }`}
        >
          <i
            class={`fas fa-cloud-upload-alt ${style.icon} ${
              uploadInProgress ? style.disabled : ''
            }`}
          ></i>
          {prompt ? prompt : 'Drag and drop your file here or'}{' '}
          <span class={style.browse}>
            {promptLinkText ? promptLinkText : 'Browse Your Files'}
          </span>
          <input
            accept={accept}
            class={style.upload}
            disabled={uploadInProgress}
            multiple={multiple}
            onChange={e => {
              const input = e.target as HTMLInputElement;
              if (input.files && input.files.length) {
                onChange(Array.from(input.files));
              }
            }}
            type="file"
          />
        </div>
        {uploadInProgress && percentComplete !== undefined && (
          <div class={style.progressContainer}>
            <div class={style.percentComplete}>
              {Number.parseInt(percentComplete)}% Complete
            </div>
            <Progress value={percentComplete} />
          </div>
        )}
      </label>
    );
  }
}
