import { h, Component } from 'preact';
import { route } from 'preact-router';
import { Mutation, withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import {
  formGroup,
  required
} from '../../../components/shared-styles/form.css';
import { blue } from '../../../components/shared-styles/buttons.css';
import Labels from '../../../components/labels';

import CAMPAIGN_QUERY from '../queries/list';

import { back, container, createButton, form } from './style.css';

const CREATE_CAMPAIGN = gql`
  mutation createCampaign($labels: [String!], $name: String!) {
    createCampaign(labels: $labels, name: $name) {
      campaignId
      createdAt
      name
      status
      updatedAt
    }
  }
`;

interface State {
  errorMessage: string;
  labels?: [string];
  name?: string;
}

class NewCampaign extends Component<any, State> {
  private confirm({ createCampaign }) {
    route(`/campaigns/${createCampaign.campaignId}/images`);
  }

  private error(data) {
    this.setState({
      errorMessage: 'Campaign creation failed'
    });
  }

  private handleSubmit = (e, mutation) => {
    e.preventDefault();
    mutation();
  };

  public render({ client }, { errorMessage, labels, name }) {
    return (
      <Mutation
        mutation={CREATE_CAMPAIGN}
        onCompleted={data => this.confirm(data)}
        onError={data => this.error(data)}
        update={(store, { data: { createCampaign } }) => {
          const { campaigns } = store.readQuery({
            query: CAMPAIGN_QUERY
          });
          campaigns.unshift(createCampaign);
        }}
        variables={{ labels, name }}
      >
        {mutation => (
          <div class={container}>
            <a href="/campaigns" class={back}>
              <i class="fas fa-arrow-left"></i>
            </a>

            <form
              class={form}
              method="post"
              onSubmit={e => this.handleSubmit(e, mutation)}
            >
              <h1>Create Campaign</h1>
              <div class={formGroup}>
                <label class={required}>
                  <span>Name</span>
                  <input
                    autofocus
                    name="name"
                    onInput={({ target }) =>
                      this.setState({
                        name: (target as HTMLInputElement).value
                      })
                    }
                    placeholder="Enter a campaign name"
                    required
                    type="text"
                    value={name}
                  />
                </label>
              </div>
              <Labels
                client={client}
                onLabelsChanged={labels => this.setState(() => ({ labels }))}
              />
              {errorMessage && <div>{errorMessage}</div>}
              <button
                class={`${blue} ${createButton}`}
                disabled={!name}
                type="submit"
              >
                Create
              </button>
            </form>
          </div>
        )}
      </Mutation>
    );
  }
}

export default withApollo(NewCampaign);
