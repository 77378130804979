import gql from 'graphql-tag';
import { GET_CAMPAIGN } from './campaign';

export const TEMPLATE_FIELDS = gql`
  fragment templateFields on Template {
    body
    campaignId
    createdAt
    createdBy
    dataFile
    fontSize
    isOutputCurrent
    name
    outputUrl
    phojiPosition
    phojiSize
    processedAt
    type
    updatedAt
    updatedBy
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate(
    $body: String
    $campaignId: String!
    $fontSize: Int!
    $name: String!
    $newMessageDataFile: String
    $phojiPosition: String!
    $phojiSize: Int!
    $type: TemplateType!
  ) {
    updateTemplate(
      body: $body
      campaignId: $campaignId
      fontSize: $fontSize
      name: $name
      newMessageDataFile: $newMessageDataFile
      phojiPosition: $phojiPosition
      phojiSize: $phojiSize
      type: $type
    ) {
      ...templateFields
    }
  }
  ${TEMPLATE_FIELDS}
`;

export const updateTemplate = async (
  mutate,
  campaignId,
  template,
  newMessageDataFileName = null
) => {
  await mutate({
    mutation: UPDATE_TEMPLATE,
    update: (store, { data: { updateTemplate } }) => {
      const { campaign } = store.readQuery({
        query: GET_CAMPAIGN,
        variables: { campaignId }
      });

      const index = campaign.templates.findIndex(t => t.name === template.name);
      campaign.templates.splice(index, 1, updateTemplate);
    },
    variables: {
      campaignId,
      ...template,
      newMessageDataFile: newMessageDataFileName
    }
  });
};
export const EXECUTE_TEMPLATE = gql`
  mutation ExecuteTemplate($campaignId: String!, $name: String!) {
    executeTemplate(campaignId: $campaignId, name: $name) {
      ...templateFields
    }
  }
  ${TEMPLATE_FIELDS}
`;

export const executeTemplate = async (mutate, campaignId, template) => {
  await mutate({
    mutation: EXECUTE_TEMPLATE,
    variables: {
      campaignId,
      name: template.name
    }
  });
};
