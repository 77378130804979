import gql from 'graphql-tag';

import { GET_CAMPAIGN } from './campaign';

export const GET_CAMPAIGN_PHOJIS = gql`
  query PhojiQuery($campaignId: String!, $cursor: String) {
    campaignPhojis(campaignId: $campaignId, cursor: $cursor) {
      page {
        name
      }
      cursor
    }
  }
`;

export const PRESIGN_PHOJI_UPLOAD = gql`
  mutation PresignPhojiUpload(
    $fileName: String!
    $fileType: String!
    $campaignId: String!
  ) {
    presignCampaignPhojiUpload(
      fileName: $fileName
      fileType: $fileType
      campaignId: $campaignId
    ) {
      url
      expectedPhoji {
        name
      }
    }
  }
`;

export const addNewPhojiToCampaignCache = (newPhoji, client, campaignId) => {
  const { campaign } = client.readQuery({
    query: GET_CAMPAIGN,
    variables: { campaignId }
  });

  campaign.phojis.page.unshift(newPhoji);

  client.writeQuery({
    data: { campaign },
    query: GET_CAMPAIGN,
    variables: { campaignId }
  });
};
