import { h, Component } from 'preact';
import ApolloClient from 'apollo-client';
import { Link, route } from 'preact-router';
import { Query, withApollo } from 'react-apollo';

import { CloseLink } from '../../../../components/close';
import { container, main } from '../../shared-styles/main.css';
import {
  button,
  primaryButton
} from '../../../../components/shared-styles/buttons.css';
import { GET_CAMPAIGN } from '../../../../queries/campaign';
import Alert, { Type } from '../../../../components/alert';
import { updateTemplate, executeTemplate } from '../../../../queries/template';
import { templateBodyPath, templateDataPath } from '../../../../shared/paths';

import style from './style.css';
import { parse, PreviewData } from '../../../../shared/message-data';
import MergeFieldsSidebar from '../../../../components/merge-fields-sidebar';
import PhojiSidebar from '../../../../components/phoji-sidebar';
import EditButton from '../../../../components/small-edit-button';
import { baseStaticUrl } from '../../../../shared/constants';
import { presignMessageDataDownload } from '../../../../queries/message-data';

const phojiRegExp = /(##[a-zA-Z0-9\s\-%_.]+##)/g;

interface Props {
  campaignId: string;
  client: ApolloClient;
  templateName: string;
}

interface State {
  currentError?: Error;
  errorMessage?: string;
  fontSize?: number;
  phojiPosition?: string;
  phojiSize?: number;
  previewData?: PreviewData;
  previews?: string[];
  previewIndex: number;
  updating: boolean;
}

class TemplateCustomize extends Component<Props, State> {
  private canGoBack() {
    const { previewIndex = 0, previews } = this.state;
    return previewIndex > 0 && previews;
  }

  private canGoForward() {
    const { previewIndex = 0, previews } = this.state;
    return previews && previewIndex < previews.length - 1;
  }

  private search(template, messageData, searchText = null) {
    const transformedMessages = messageData.map(md =>
      template.body.replace(/\{\{[a-zA-Z0-9\s\.]*\}\}/g, m => {
        const tag = m.substring(2, m.length - 2);
        return md[tag] === undefined ? '' : md[tag];
      })
    );

    this.setState({
      previewIndex: 0,
      previews: searchText
        ? transformedMessages.filter(t => t.includes(searchText))
        : transformedMessages
    });
  }

  public render(
    { campaignId, client, templateName }: Props,
    {
      currentError,
      errorMessage,
      fontSize,
      phojiPosition,
      phojiSize,
      previewData,
      previewIndex = 0,
      previews,
      updating
    }: State
  ) {
    return (
      <Query query={GET_CAMPAIGN} variables={{ campaignId }}>
        {({ data, error, loading }) => {
          if (loading) {
            return null;
          }
          if (error) {
            return (
              <Alert error={error} type={Type.Error}>
                {error.message}
              </Alert>
            );
          }

          const { campaign } = data;
          const { phojis } = campaign;
          const template = campaign.templates.find(
            t => t.name === templateName
          );
          if (!previewData) {
            // async/await does not work here, page renders blank
            presignMessageDataDownload(client.mutate, template.dataFile).then(
              result => {
                parse(result).then(previewData =>
                  this.setState({ previewData })
                );
              }
            );
            return;
          }

          if (template.fontSize && fontSize === undefined) {
            this.setState({
              fontSize: template.fontSize,
              updating: true
            });
          }

          if (template.phojiSize && phojiSize === undefined) {
            this.setState({
              phojiSize: template.phojiSize,
              updating: true
            });
          }

          if (template.phojiPosition && phojiPosition === undefined) {
            this.setState({
              phojiPosition: template.phojiPosition,
              updating: true
            });
          }

          const { data: messageData } = previewData;

          if (!previews) {
            // Do an empty search to setup previews
            this.search(template, messageData);
            return;
          }

          return (
            <div class={container}>
              {errorMessage && (
                <Alert error={currentError} type={Type.Error}>
                  {errorMessage}
                </Alert>
              )}
              <CloseLink href="/campaigns" />
              <div class={style.flexContainer}>
                <section class={`${main} ${style.main}`}>
                  <h1>
                    {campaign.name} - <em>{template.name}</em>
                  </h1>
                  <h2>Customize Your Message</h2>
                  <div class={style.editTemplate}>
                    <span>{template.name} Template</span>
                    <EditButton
                      href={templateBodyPath(campaignId, template.name)}
                    />
                  </div>

                  <div class={style.displaySettings}>
                    <h2>Phoji Display Settings</h2>
                    <span class={style.field}>
                      Font Size
                      <select
                        value={fontSize || template.fontSize}
                        onChange={e =>
                          this.setState({ fontSize: parseInt(e.target.value) })
                        }
                      >
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                        <option value={14}>14</option>
                        <option value={18}>18</option>
                        <option value={24}>24</option>
                        <option value={32}>32</option>
                      </select>
                    </span>
                    <span class={style.field}>
                      Phoji Size
                      <input
                        max={100}
                        min={10}
                        onInput={e =>
                          this.setState({ phojiSize: parseInt(e.target.value) })
                        }
                        type="number"
                        value={phojiSize || template.phojiSize}
                      />
                    </span>
                    <span class={style.field}>
                      Phoji Position
                      <select
                        value={phojiPosition || template.phojiPosition}
                        onChange={e =>
                          this.setState({ phojiPosition: e.target.value })
                        }
                      >
                        <option value="text-bottom">Text Bottom</option>
                        <option value="text-top">Text Top</option>
                        <option value="inital">Initial</option>
                        <option value="inherit">Inherit</option>
                        <option value="bottom">Bottom</option>
                        <option value="middle">Middle</option>
                        <option value="top">Top</option>
                        <option value="baseline">Baseline</option>
                        <option value="sub">Sub</option>
                        <option value="super">Super</option>
                      </select>
                    </span>
                  </div>

                  <div class={style.messagePreviews}>
                    <div class={style.flexContainer}>
                      <h2 class={`${style.flexFill} ${style.previewHeader}`}>
                        Message Previews
                      </h2>
                      <input
                        class={style.search}
                        onInput={e =>
                          this.search(template, messageData, e.target.value)
                        }
                        placeholder="Search for a preview..."
                        type="text"
                      />
                      <button
                        disabled={!this.canGoBack()}
                        class={style.previewPaginationButton}
                        onClick={() =>
                          this.setState({ previewIndex: previewIndex - 1 })
                        }
                      >
                        <i class="fas fa-chevron-left"></i>
                      </button>
                      <button
                        disabled={!this.canGoForward()}
                        class={style.previewPaginationButton}
                        onClick={() =>
                          this.setState({ previewIndex: previewIndex + 1 })
                        }
                      >
                        <i class="fas fa-chevron-right"></i>
                      </button>
                    </div>
                    <div
                      class={style.preview}
                      style={{
                        'font-size': `${fontSize || template.fontSize}px`
                      }}
                    >
                      {previews.length > previewIndex
                        ? previews[previewIndex]
                            .split(phojiRegExp)
                            .map((s, i) => {
                              if (i % 2 === 0) {
                                return s;
                              }
                              const tag = s.substring(2, s.length - 2);
                              return (
                                <img
                                  style={{
                                    'vertical-align':
                                      phojiPosition || template.phojiPosition,
                                    height: phojiSize || template.phojiSize,
                                    width: phojiSize || template.phojiSize
                                  }}
                                  src={`${baseStaticUrl}/${campaignId}/${tag}?s=100`}
                                />
                              );
                            })
                        : 'No messages match your search'}
                    </div>
                  </div>
                </section>

                <div class={style.rightColumn}>
                  <MergeFieldsSidebar
                    editButtonHref={templateDataPath(campaignId, template.name)}
                    fields={previewData.fields}
                  />
                  {phojis && <PhojiSidebar phojis={phojis.page.slice(0, 15)} />}
                </div>
              </div>
              <div class={style.buttonContainer}>
                <Link
                  class={`${button} ${primaryButton}`}
                  href="/campaigns"
                  onClick={async e => {
                    e.preventDefault();
                    template.fontSize = fontSize || template.fontSize;
                    template.phojiPosition =
                      phojiPosition || template.phojiPosition;
                    template.phojiSize = phojiSize || template.phojiSize;
                    await updateTemplate(client.mutate, campaignId, template);
                    await executeTemplate(client.mutate, campaignId, template);
                    route('/campaigns');
                  }}
                >
                  {updating ? 'Update' : 'Create'}
                </Link>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(TemplateCustomize);
