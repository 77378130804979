function templateBase(campaignId: string, templateName: string) {
  return `/campaigns/${campaignId}/templates/${encodeURIComponent(
    templateName
  )}`;
}

export function templateBodyPath(campaignId: string, templateName: string) {
  return `${templateBase(campaignId, templateName)}/body`;
}

export function templateCustomizePath(
  campaignId: string,
  templateName: string
) {
  return `${templateBase(campaignId, templateName)}/customize`;
}

export function templateDataPath(campaignId: string, templateName: string) {
  return `${templateBase(campaignId, templateName)}/data`;
}

export function newTemplatePath(campaignId: string) {
  return `/campaigns/${campaignId}/templates/new`;
}
