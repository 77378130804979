import { h } from 'preact';
import { baseStaticUrl } from '../../shared/constants';

interface Phoji {
  name: string;
}

interface Props {
  className?: string;
  phojis: Phoji[];
  draggable?: boolean;
}

import style from './style.css';

export default ({ className, draggable, phojis }: Props) => (
  <section class={className || ''}>
    <h1>Job Phojis</h1>
    <ul class={style.phojiList}>
      {phojis.map(({ name }) => (
        <li class={draggable ? style.draggable : ''}>
          <img
            draggable={!!draggable}
            onDragStart={e => {
              if (!draggable || !e.dataTransfer || !e.target) {
                return;
              }
              const img = e.target as HTMLImageElement;
              const src = img.getAttribute('src') || '';
              const path = new URL(src).pathname;
              const filename = path.split('/').pop();
              e.dataTransfer.dropEffect = 'copy';
              e.dataTransfer.setDragImage(img, 0, 0);
              e.dataTransfer.setData('text', `##${filename || ''}##`);
            }}
            src={`${baseStaticUrl}/${name}?s=100`}
          />
        </li>
      ))}
    </ul>
  </section>
);
