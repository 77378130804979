export enum CampaignStatus {
  Archived = 'archived',
  Created = 'created',
  Executed = 'executed',
  Executing = 'executing',
  Queued = 'queued'
}

export interface Campaign {
  campaignId: string;
  createdAt: string;
  name: string;
  status: CampaignStatus;
  updatedAt: string;
}
