import gql from 'graphql-tag';

const PRESIGN_DOWNLOAD = gql`
  mutation PresignMessageDataDownload($dataFilePath: String!) {
    presignMessageDataDownload(dataFilePath: $dataFilePath)
  }
`;

export const presignMessageDataDownload = async (mutate, dataFilePath) => {
  const {
    data: { presignMessageDataDownload }
  } = await mutate({
    mutation: PRESIGN_DOWNLOAD,
    variables: {
      dataFilePath
    }
  });
  return presignMessageDataDownload;
};
