import { h } from 'preact';
import { active, campaignLink, container, inactive, table } from './style.css';
import { Campaign } from '../../../model/campaign';
import dateFormatter from '../../../shared/date-formatter';

interface Props {
  campaigns: Campaign[];
  onCampaignSelected: (campaign: Campaign) => void;
}

export default ({ campaigns, onCampaignSelected }: Props): JSX.Element => (
  <div class={container}>
    <table class={table}>
      <tr>
        <th>Campaign Name</th>
        <th>Status</th>
        <th>Created</th>
        <th>Updated</th>
      </tr>
      {campaigns.map(c => (
        <tr>
          <td>
            <button class={campaignLink} onClick={() => onCampaignSelected(c)}>
              {c.name}
            </button>
          </td>
          <td class={c.status === 'archived' ? inactive : active}>
            {c.status === 'archived' ? 'Archived' : 'Active'}
          </td>
          <td>{dateFormatter.format(new Date(c.createdAt))}</td>
          <td>{dateFormatter.format(new Date(c.updatedAt))}</td>
        </tr>
      ))}
    </table>
  </div>
);
