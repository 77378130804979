import { h } from 'preact';

import EditButton from '../small-edit-button';

import style from './style';

interface Props {
  draggable?: boolean;
  fields: string[];
  editButtonHref?: string;
}

export default ({ draggable, editButtonHref, fields }: Props) => (
  <section>
    <h1>
      <span class={style.head}>Merge fields</span>
      {editButtonHref ? <EditButton href={editButtonHref} /> : null}
    </h1>
    <ul class={style.mergeFieldList}>
      {fields.map(f => (
        <li
          class={draggable ? style.draggable : ''}
          draggable={!!draggable}
          onDragStart={e => {
            if (!draggable || !e.dataTransfer || !e.target) {
              return;
            }

            const text = (e.target as HTMLUListElement).innerText || '';
            const pattern = text.toLowerCase().includes('phoji')
              ? `##{{${text}}}##`
              : `{{${text}}}`;
            e.dataTransfer.dropEffect = 'copy';
            e.dataTransfer.setDragImage(e.target as Element, 0, 0);
            e.dataTransfer.setData('text', pattern);
          }}
        >
          {f}
        </li>
      ))}
    </ul>
  </section>
);
