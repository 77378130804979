import gql from 'graphql-tag';
import { h, Component } from 'preact';
import { Link } from 'preact-router';

import { CampaignStatus } from '../../../model/campaign';
import { CloseButton } from '../../close';
import Labels from '../../labels';
import { GET_CAMPAIGN } from '../../../queries/campaign';
import UploadButton from '../../upload-button';

import { button, primaryButton } from '../../shared-styles/buttons.css';
import { newTemplatePath, templateDataPath } from '../../../shared/paths';
import style from './style.css';
import { Query, withApollo } from 'react-apollo';
import ApolloClient from 'apollo-client';
import { baseStaticUrl } from '../../../shared/constants';
import {
  addNewPhojiToCampaignCache,
  PRESIGN_PHOJI_UPLOAD
} from '../../../queries/phoji';

const ARCHIVE_CAMPAIGN = gql`
  mutation archiveCampaign($campaignId: String!) {
    archiveCampaign(campaignId: $campaignId) {
      status
    }
  }
`;

const UNARCHIVE_CAMPAIGN = gql`
  mutation unarchiveCampaign($campaignId: String!) {
    unarchiveCampaign(campaignId: $campaignId) {
      status
    }
  }
`;

const UPDATE_LABELS = gql`
  mutation updateCampaignLabels($campaignId: String!, $labels: [String!]!) {
    updateCampaignLabels(campaignId: $campaignId, labels: $labels) {
      labels
    }
  }
`;

interface Props {
  campaignId?: string;
  client: ApolloClient;
  close: () => void;
  opened: boolean;
}

interface State {
  status: CampaignStatus;
  uploading: boolean;
}

class CampaignDetail extends Component<Props, State> {
  private dateFormat = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });

  private readCampaignCache = (cache, campaignId) => {
    const { campaign } = cache.readQuery({
      query: GET_CAMPAIGN,
      variables: { campaignId }
    });
    return campaign;
  };

  private writeCampaignCache = (cache, campaign) => {
    cache.writeQuery({
      data: { campaign },
      query: GET_CAMPAIGN,
      variables: { campaignId: campaign.campaignId }
    });
  };

  private archiveCampaign = async (mutate, campaignId) => {
    await mutate({
      mutation: ARCHIVE_CAMPAIGN,
      update: (
        cache,
        {
          data: {
            archiveCampaign: { status }
          }
        }
      ) => {
        const campaign = this.readCampaignCache(cache, campaignId);
        campaign.status = status;
        this.writeCampaignCache(cache, campaign);
      },
      variables: {
        campaignId
      }
    });
  };

  private unarchiveCampaign = async (mutate, campaignId) => {
    await mutate({
      mutation: UNARCHIVE_CAMPAIGN,
      update: (
        cache,
        {
          data: {
            unarchiveCampaign: { status }
          }
        }
      ) => {
        const campaign = this.readCampaignCache(cache, campaignId);
        campaign.status = status;
        this.writeCampaignCache(cache, campaign);
      },
      variables: {
        campaignId
      }
    });
  };

  private updateLabels = async (mutate, campaignId, labels) => {
    await mutate({
      mutation: UPDATE_LABELS,
      update: (
        cache,
        {
          data: {
            updateCampaignLabels: { labels }
          }
        }
      ) => {
        const campaign = this.readCampaignCache(cache, campaignId);
        campaign.labels = labels;
        this.writeCampaignCache(cache, campaign);
      },
      variables: {
        campaignId,
        labels
      }
    });
  };

  private uploadPhoji = async (file, url) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'json';
      xhr.addEventListener('readystatechange', e => {
        if (xhr && xhr.readyState === 4) {
          xhr.status === 200
            ? resolve(e)
            : reject(
                Error(
                  `Upload to ${url} failed. Server returned status code ${this.xhr.status} with status ${this.xhr.statusText}.`
                )
              );
        }
      });
      xhr.open('PUT', url);
      xhr.send(file);
    });

  private upload = async (files: File[]) => {
    const uploadQueue = Array.from(files).reverse();

    const {
      campaignId,
      client,
      client: { mutate }
    } = this.props;

    while (uploadQueue.length) {
      const file = uploadQueue.pop();

      const {
        data: { presignCampaignPhojiUpload }
      } = await mutate({
        mutation: PRESIGN_PHOJI_UPLOAD,
        variables: {
          fileName: file.name,
          fileType: file.type,
          campaignId
        }
      });

      const { url, expectedPhoji: phoji } = presignCampaignPhojiUpload;
      await this.uploadPhoji(file, url);
      addNewPhojiToCampaignCache(phoji, client, campaignId);
      // Trigger a rerender
      this.setState({});
    }
  };

  private shouldPoll(campaign) {
    return (
      campaign && campaign.templates.some(t => t.processedAt !== t.updatedAt)
    );
  }

  public render(
    { campaignId, client, close, opened }: Props,
    { status, uploading }: State
  ) {
    if (!campaignId) {
      return null;
    }
    return (
      <Query query={GET_CAMPAIGN} variables={{ campaignId }}>
        {({ data, error, loading, startPolling, stopPolling }) => {
          if (error) {
            return 'error';
          }

          const { campaign } = data;

          if (campaign && !status) {
            this.setState({
              status: campaign.status
            });
          }

          if (this.shouldPoll(campaign)) {
            console.log('Starting to poll', campaign && campaign.templates);
            startPolling(10000);
          } else {
            console.log('Stop polling', campaign && campaign.templates);
            stopPolling();
          }

          return (
            <div>
              {campaign && (
                <div class={`${style.modal} ${opened ? style.opened : ''}`}>
                  <CloseButton
                    class={style.closeButton}
                    onClick={() => close()}
                  />

                  <div class={style.flexContainer}>
                    <h1>{campaign.name}</h1>
                    <div class={style.leftContainer}>
                      <div class={style.innerLeft}>
                        <div class={style.templates}>
                          <h2>
                            Message Templates
                            <Link
                              class={`${button} ${primaryButton} ${style.smallButton}`}
                              href={newTemplatePath(campaign.campaignId)}
                            >
                              Add New Template
                            </Link>
                          </h2>
                          {campaign.templates.length === 0 ? (
                            <div>No templates yet!</div>
                          ) : (
                            <ul class={style.templateList}>
                              {campaign.templates.map(t => {
                                return (
                                  <li>
                                    <span class={style.templateName}>
                                      {t.name}
                                    </span>
                                    {t.processedAt === t.updatedAt ? (
                                      <a
                                        class={style.templateButton}
                                        href={t.outputUrl}
                                      >
                                        <i class="fas fa-download"></i>
                                      </a>
                                    ) : (
                                      <i
                                        class={`fas fa-cog fa-spin ${style.templateButton}`}
                                      ></i>
                                    )}
                                    {/*
                                    <button class={style.templateButton}>
                                      <i class="far fa-copy"></i>
                                    </button>
                                    */}
                                    <Link
                                      class={`${button} ${primaryButton} ${style.editButton}`}
                                      href={templateDataPath(
                                        campaign.campaignId,
                                        t.name
                                      )}
                                    >
                                      Edit
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                        <div class={style.phojis}>
                          <h2>
                            Available Phojis{' '}
                            <UploadButton
                              accept="image/*"
                              loading={uploading}
                              multiple={true}
                              onChange={async files => {
                                this.setState({ uploading: true });
                                await this.upload(files);
                                this.setState({ uploading: false });
                              }}
                              title="Upload Images"
                            />
                          </h2>
                          {campaign.phojis.page.length === 0 ? (
                            <div>No Phojis yet!</div>
                          ) : (
                            <ul class={style.phojiList}>
                              {campaign.phojis.page
                                .slice(0, 15)
                                .map(({ name }) => {
                                  return (
                                    <li>
                                      <img
                                        src={`${baseStaticUrl}/${name}?s=50`}
                                      />
                                    </li>
                                  );
                                })}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class={style.rightContainer}>
                      <div>
                        <h2>Status</h2>
                        <div class={style.status}>
                          <input
                            type="checkbox"
                            class={style.activeSwitch}
                            onChange={async e => {
                              const { checked } = e.target as HTMLInputElement;
                              const { mutate } = client;

                              if (checked) {
                                await this.unarchiveCampaign(
                                  mutate,
                                  campaignId
                                );
                              } else {
                                await this.archiveCampaign(mutate, campaignId);
                              }
                            }}
                            checked={
                              campaign.status !== CampaignStatus.Archived
                            }
                          />
                          {campaign.status === CampaignStatus.Archived
                            ? 'Archived'
                            : 'Active'}
                        </div>
                      </div>

                      <div class={style.labels}>
                        <Labels
                          className={style.smallerLabels}
                          client={client}
                          labels={campaign.labels}
                          onLabelsChanged={async labels =>
                            await this.updateLabels(
                              client.mutate,
                              campaignId,
                              labels
                            )
                          }
                        />
                      </div>

                      <div class={style.dates}>
                        <div>
                          Created:{' '}
                          {this.dateFormat.format(new Date(campaign.createdAt))}
                        </div>
                        <div>
                          Updated:{' '}
                          {this.dateFormat.format(new Date(campaign.updatedAt))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                class={style.background}
                onClick={() => close()}
                style={{
                  display: opened ? 'block' : 'none'
                }}
              ></div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(CampaignDetail);
