import { h, Component } from 'preact';
import { Route, Router, RouterOnChangeArgs } from 'preact-router';

import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';

import Header from './header';

// Code-splitting is automated for routes
import Home from '../routes/home';
import Campaigns from '../routes/campaigns/index';
import Login from '../routes/login';
import NewCampaign from '../routes/campaigns/new';
import CampaignImages from '../routes/campaigns/images';
import NewTemplate from '../routes/campaigns/templates/new';
import TemplateCustomize from '../routes/campaigns/templates/customize';
import TemplateData from '../routes/campaigns/templates/data';
import TemplateBody from '../routes/campaigns/templates/body';
import Register from '../routes/register';
import ShowCampaign from '../routes/campaigns/show';

import { baseApiUrl } from '../shared/constants';
import { getToken } from '../shared/token';
import Phojis from '../routes/phojis';

const httpLink = new HttpLink({
  uri: `${baseApiUrl}/graphql`
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject: o => {
      switch (o.__typename) {
        case 'Campaign':
          return o['campaignId'];
        case 'Template':
          return `${o['campaignId']}-${o['name']}`;
        default:
          return defaultDataIdFromObject(o);
      }
    }
  }),
  link: authLink.concat(httpLink),
  typeDefs: `
    enum TemplateType {
      email,
      mobile,
      web
    }
  `
});

interface State {
  currentUrl: string;
}

export default class App extends Component<any, State> {
  private handleRoute = (e: RouterOnChangeArgs) => {
    this.setState({
      currentUrl: e.url
    });
  };

  public render() {
    return (
      <ApolloProvider client={client}>
        <div id="container">
          <Header />
          <Router onChange={this.handleRoute}>
            <Route path="/" component={Login} />
            <Route path="/phojis" component={Phojis} />
            <Route path="/campaigns" component={Campaigns} />
            <Route path="/campaigns/new" component={NewCampaign} />
            <Route path="/campaigns/:campaignId" component={ShowCampaign} />
            <Route
              path="/campaigns/:campaignId/images"
              component={CampaignImages}
            />
            <Route
              path="/campaigns/:campaignId/templates/new"
              component={NewTemplate}
            />
            <Route
              path="/campaigns/:campaignId/templates/:templateName/data"
              component={TemplateData}
            />
            <Route
              path="/campaigns/:campaignId/templates/:templateName/body"
              component={TemplateBody}
            />
            <Route
              path="/campaigns/:campaignId/templates/:templateName/customize"
              component={TemplateCustomize}
            />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
          </Router>
        </div>
      </ApolloProvider>
    );
  }
}
