import { h } from 'preact';
import Tile from '../tile';
import { container } from './style.css';
import { Campaign } from '../../../model/campaign';

interface Props {
  campaigns: Campaign[];
  onCampaignSelected: (campaign: Campaign) => void;
}

export default ({ campaigns, onCampaignSelected }: Props): JSX.Element => (
  <ul class={container}>
    {campaigns.map(c => (
      <li>
        <Tile
          onClick={() => {
            onCampaignSelected(c);
          }}
          {...c}
        />
      </li>
    ))}
  </ul>
);
