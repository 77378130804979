import { h, Component } from 'preact';
import { Query, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import Alert, { Type } from '../../components/alert';
import UploadButton from '../../components/upload-button';

import { baseStaticUrl } from '../../shared/constants';
import style from './style.css';
import {
  button,
  blue,
  primary,
  primaryInverse,
  menuButton
} from '../../components/shared-styles/buttons.css';
import PlanSummary from '../../components/plan-summary';
import ApolloClient from 'apollo-client';
import { GET_CURRENT_PLAN_STATE } from '../../queries/plan';
import loadImage from 'blueimp-load-image';

const GET_CONSUMER_PHOJIS = gql`
  query PhojiQuery($cursor: String) {
    consumerPhojis(cursor: $cursor) {
      page {
        name
      }
      cursor
    }
  }
`;

const PRESIGN = gql`
  mutation PresignPhojiUpload($fileName: String!, $fileType: String!) {
    presignConsumerPhojiUpload(fileName: $fileName, fileType: $fileType) {
      url
      expectedPhoji {
        name
      }
    }
  }
`;

interface Props {
  client: ApolloClient;
}

interface State {
  percentUploaded?: number;
  phojiActionState?: object;
  numberOfFilesLeft?: number;
  totalFiles?: number;
  uploadInProgress?: boolean;
  currentPlanState?: any;
}

class Phojis extends Component<Props, State> {
  public constructor(props) {
    super(props);
    this.fetchCurrentPlanState();
  }

  private fetchCurrentPlanState = async () => {
    const results = await this.props.client.query({
      fetchPolicy: 'network-only',
      query: GET_CURRENT_PLAN_STATE
    });

    this.setState({
      currentPlanState: results.data.currentPlanState
    });
  };

  private addNewPhojiToCache = (newPhoji: any, client: any) => {
    const results = client.readQuery({
      query: GET_CONSUMER_PHOJIS
    });

    results.consumerPhojis.page.unshift(newPhoji);

    client.writeQuery({
      data: results,
      query: GET_CONSUMER_PHOJIS
    });
  };

  private uploadPhoji = async (file, url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener('progress', ({ loaded, total }) =>
        this.setState({ percentUploaded: (100 * loaded) / total })
      );

      xhr.addEventListener('readystatechange', e => {
        if (xhr.readyState === 4) {
          this.setState({ percentUploaded: 100 });
          xhr.status === 200 ? resolve(e) : reject(e);
        }
      });
      xhr.open('PUT', url);
      xhr.send(file);
    });
  };

  private processPhoji = async (file: File, url: string) => {
    if (!file.type.startsWith('image/')) {
      alert('Please upload a valid image file.');
      return;
    }

    // TODO: Wrap this in a promise
    return new Promise((resolve, reject) => {
      // Use blueimp-load-image to load and correct the image orientation
      loadImage(
        file,
        (canvas, data) => {
          if (canvas.type === 'error') {
            console.error('Error loading image');
            reject('Error loading image');
            return;
          }

          /*
          const previewDiv = document.getElementById('preview');
          previewDiv.innerHTML = ''; // Clear any existing previews

          const imgElement = document.createElement('img');
          imgElement.src = canvas.toDataURL(file.type || 'image/jpeg');
          imgElement.style.maxWidth = '300px';
          imgElement.style.margin = '10px';
          imgElement.alt = 'Processed Image Preview';

          previewDiv.appendChild(imgElement);
          */

          // Convert the canvas to a Blob
          canvas.toBlob(
            async blob => {
              // Optionally, you can further process the blob here (e.g., resize)

              // Upload the corrected image blob
              //uploadImage(blob, file.name);
              await this.uploadPhoji(blob, url);
              resolve('Uploaded');
            },
            file.type || 'image/jpeg',
            0.95
          ); // Adjust quality as needed
        },
        {
          orientation: true, // Automatically correct the orientation based on EXIF
          canvas: true, // Return a canvas element instead of a data URL
          // Optionally, you can add other options like maxWidth, maxHeight, etc.
          maxWidth: 1920,
          maxHeight: 1920,
          cover: false
        }
      );
    });
  };

  private upload = async (files: File[]) => {
    const q = Array.from(files).reverse();
    const totalFiles = q.length;

    const {
      client,
      client: { mutate }
    } = this.props;

    while (q.length) {
      const file = q.pop();

      this.setState({
        percentUploaded: 0,
        numberOfFilesLeft: q.length + 1,
        totalFiles,
        uploadInProgress: true
      });

      const {
        data: { presignConsumerPhojiUpload }
      } = await mutate({
        mutation: PRESIGN,
        variables: {
          fileName: file?.name,
          fileSize: file?.size,
          fileType: file?.type
        }
      });

      const { url, expectedPhoji: phoji } = presignConsumerPhojiUpload;
      //await this.uploadPhoji(file, url);
      await this.processPhoji(file, url);
      this.addNewPhojiToCache(phoji, client);
    }

    this.setState(() => ({
      uploadInProgress: false
    }));

    this.fetchCurrentPlanState();
  };

  private loadMore = ({ cursor, fetchMore }) => {
    fetchMore({
      variables: {
        cursor
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        fetchMoreResult.phojis.page = [
          ...prev.phojis.page,
          ...fetchMoreResult.phojis.page
        ];

        return fetchMoreResult;
      }
    });
  };

  public render(
    {},
    {
      currentPlanState,
      numberOfFilesLeft,
      phojiActionState,
      percentUploaded,
      uploadInProgress
    }
  ) {
    return (
      <Query query={GET_CONSUMER_PHOJIS}>
        {({ data, error, fetchMore, loading }) => {
          if (loading) {
            return <div>Loading</div>;
          }
          if (error) {
            return <Alert type={Type.Error}>{error.message}</Alert>;
          }
          const {
            consumerPhojis: { cursor, page: phojis }
          } = data;

          return (
            <div class={style.container}>
              <PlanSummary currentPlanState={this.state.currentPlanState} />
              <div>
                {currentPlanState?.phojisRemaining > 0 ? (
                  <UploadButton
                    accept="image/*"
                    loading={uploadInProgress}
                    multiple={false}
                    onChange={files => this.upload(files)}
                    title="Upload image"
                    tooltip="Upload phoji image"
                  />
                ) : (
                  currentPlanState && (
                    <Alert type={Type.Warning}>
                      <div>
                        No Phojis remaining in current plan. Plan resets on{' '}
                        {new Date(
                          currentPlanState?.nextStartDate
                        ).toLocaleString()}
                        .
                      </div>
                    </Alert>
                  )
                )}
                <ul class={style.phojis}>
                  {uploadInProgress &&
                    Array.from({ length: numberOfFilesLeft }).map((_, i) => {
                      const isLast = i === numberOfFilesLeft - 1;
                      const clip = isLast ? `${100 - percentUploaded}px` : 0;
                      return (
                        <li class="animated fadeIn">
                          <svg
                            style={{ 'clip-path': `inset(0 0 0 ${clip})` }}
                            class={style.placeholder}
                          />
                        </li>
                      );
                    })}
                  {phojis?.map(({ name }) => (
                    <li class={`animated fadeIn ${style.phoji}`}>
                      {phojiActionState && phojiActionState[name] && (
                        <div
                          class={`${
                            phojiActionState && phojiActionState[name]
                              ? style.overlay
                              : style.hidden
                          }`}
                        >
                          <Alert type={phojiActionState[name].type}>
                            {phojiActionState[name].message}
                          </Alert>
                        </div>
                      )}
                      <div>
                        <img
                          class={style.placeholder}
                          src={`${baseStaticUrl}/${name}?s=160`}
                        />
                      </div>
                      <div class={style.phojiMenu}>
                        <button
                          class={`${button} ${blue} ${menuButton}`}
                          onClick={async () => {
                            const fileName = name.substring(
                              name.lastIndexOf('/') + 1
                            );

                            const imageUrl = `${baseStaticUrl}/${name}?s=160`;

                            const LIMIT = 10;
                            for (let i = 0; i < LIMIT; i++) {
                              try {
                                // Fetch the image as a blob
                                const response = await fetch(imageUrl);

                                if (!response.ok) {
                                  throw new Error(
                                    `Failed to fetch image. Status: ${response.status}`
                                  );
                                }

                                const blob = await response.blob();

                                // Create a temporary URL for the blob
                                const url = window.URL.createObjectURL(blob);

                                // Create a temporary <a> element
                                const a = document.createElement('a');
                                a.href = url;

                                // Extract the filename from the image URL
                                a.download = fileName;

                                // Append the <a> to the document body
                                document.body.appendChild(a);

                                // Programmatically click the <a> to trigger the download
                                a.click();

                                // Clean up by removing the <a> and revoking the object URL
                                a.remove();
                                window.URL.revokeObjectURL(url);

                                this.setState(prev => {
                                  const actions =
                                    structuredClone(prev.phojiActionState) ||
                                    {};
                                  actions[name] = {
                                    type: Type.Info,
                                    message: 'Successfully downloaded!'
                                  };
                                  const newState = {
                                    phojiActionState: actions
                                  };
                                  return newState;
                                });

                                setTimeout(() => {
                                  this.setState(prev => {
                                    const actions =
                                      structuredClone(prev.phojiActionState) ||
                                      {};
                                    delete actions[name];
                                    const newState = {
                                      phojiActionState: actions
                                    };
                                    return newState;
                                  });
                                }, 3000);
                                break;
                              } catch (error) {
                                if (i === LIMIT - 1) {
                                  this.setState(prev => {
                                    const actions =
                                      structuredClone(prev.phojiActionState) ||
                                      {};
                                    actions[name] = {
                                      type: Type.Error,
                                      message: 'Download failed'
                                    };
                                    const newState = {
                                      phojiActionState: actions
                                    };
                                    return newState;
                                  });

                                  setTimeout(() => {
                                    this.setState(prev => {
                                      const actions =
                                        structuredClone(
                                          prev.phojiActionState
                                        ) || {};
                                      delete actions[name];
                                      const newState = {
                                        phojiActionState: actions
                                      };

                                      return newState;
                                    });
                                  }, 3000);
                                }
                              }
                            }
                          }}
                        >
                          <i class={'fas fa-download'} />
                          Download
                        </button>
                        <button
                          class={`${button} ${primary} ${menuButton}`}
                          onClick={async () => {
                            try {
                              const phojiUrl = `${baseStaticUrl}/${name}?s=160`;
                              await navigator.clipboard.writeText(phojiUrl);
                              this.setState(prev => {
                                const actions =
                                  structuredClone(prev.phojiActionState) || {};
                                actions[name] = {
                                  type: Type.Info,
                                  message: 'Successfully copied URL!'
                                };
                                const newState = {
                                  phojiActionState: actions
                                };
                                return newState;
                              });

                              setTimeout(() => {
                                this.setState(prev => {
                                  const actions =
                                    structuredClone(prev.phojiActionState) ||
                                    {};
                                  delete actions[name];
                                  const newState = {
                                    phojiActionState: actions
                                  };
                                  return newState;
                                });
                              }, 3000);
                            } catch (ex) {
                              this.setState(prev => {
                                const actions =
                                  structuredClone(prev.phojiActionState) || {};
                                actions[name] = {
                                  type: Type.Error,
                                  message: 'URL copy failed'
                                };
                                const newState = {
                                  phojiActionState: actions
                                };
                                return newState;
                              });

                              setTimeout(() => {
                                this.setState(prev => {
                                  const actions =
                                    structuredClone(prev.phojiActionState) ||
                                    {};
                                  delete actions[name];
                                  const newState = {
                                    phojiActionState: actions
                                  };

                                  return newState;
                                });
                              }, 3000);
                            }
                          }}
                        >
                          <i class={'fas fa-clipboard'} />
                          Copy URL
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
                {cursor && (
                  <button
                    class={primaryInverse}
                    onClick={() => this.loadMore({ cursor, fetchMore })}
                  >
                    Load more
                  </button>
                )}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(Phojis);
