import gql from 'graphql-tag';

export const GET_CURRENT_PLAN_STATE = gql`
  query CurrentPlanState {
    currentPlanState {
      nextStartDate
      phojisRemaining
      phojisUsed
      planLimit
      startDate
    }
  }
`;
