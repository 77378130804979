import { parse as papaparse } from 'papaparse';

export interface PreviewData {
  data: object[];
  fields: string[];
}

export const parse = async (arg: File | string): Promise<PreviewData> => {
  return new Promise(resolve => {
    papaparse(arg, {
      complete: results => {
        const {
          data,
          meta: { fields }
        } = results;
        resolve({ data, fields });
      },
      download: !(arg instanceof File),
      header: true,
      preview: 10
    });
  });
};

export const parseFile = async (file: File) => {
  return new Promise(resolve => {
    papaparse(file, {
      complete: results => {
        const {
          data,
          meta: { fields }
        } = results;
        resolve({ data, fields });
      },
      header: true,
      preview: 10
    });
  });
};

export const parseRemote = async (url: string) => {
  return new Promise(resolve => {
    papaparse(url, {
      complete: results => {
        const {
          data,
          meta: { fields }
        } = results;
        resolve({ data, fields });
      },
      download: true,
      header: true,
      preview: 10
    });
  });
};

/*
export const parse = messageData => {
  const {
    data,
    meta: { fields }
  } = papaparse(messageData, { header: true });
  return {
    data,
    fields
  };
};

export const mergeFields = messageData => {
  const { fields } = parse(messageData);
  return fields;
};

export const toObject = messageData => {
  const { data } = parse(messageData);
  return data;
};
*/
