import { h, Component } from 'preact';
import ApolloClient from 'apollo-client';
import { Link } from 'preact-router';
import { Query, withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import { CloseLink } from '../../../../components/close';
import {
  cancel,
  container,
  formButton,
  main,
  skipLink,
  uploadArea
} from '../../shared-styles/main.css';
import {
  button,
  primaryButton
} from '../../../../components/shared-styles/buttons.css';
import { GET_CAMPAIGN } from '../../../../queries/campaign';
import Alert, { Type } from '../../../../components/alert';
import { updateTemplate } from '../../../../queries/template';
import Upload from '../../../../components/upload';
import MessageDataPreview from '../../../../components/templates/message-data-preview';
import PhojiSidebar from '../../../../components/phoji-sidebar';
import { templateBodyPath } from '../../../../shared/paths';

import style from './style.css';
import { parse, PreviewData } from '../../../../shared/message-data';
import { baseStaticUrl } from '../../../../shared/constants';
import { presignMessageDataDownload } from '../../../../queries/message-data';

const PRESIGN = gql`
  mutation PresignMessageDataUpload(
    $campaignId: String!
    $fileName: String!
    $fileType: String!
    $templateName: String!
  ) {
    presignMessageDataUpload(
      campaignId: $campaignId
      fileName: $fileName
      fileType: $fileType
      templateName: $templateName
    )
  }
`;

interface Props {
  campaignId: string;
  client: ApolloClient;
  templateName: string;
}

interface State {
  currentError?: Error;
  errorMessage?: string;
  percentComplete?: number;
  previewData?: PreviewData;
  reachedStep3: boolean;
  showSkip: boolean;
  uploadComplete: boolean;
  uploadInProgress: boolean;
  userCanceled: boolean;
}

class TemplateData extends Component<Props, State> {
  private xhr: XMLHttpRequest | undefined;
  private steps = {
    one: {
      showSkip: true,
      uploadComplete: false,
      uploadInProgress: false
    },
    two: {
      showSkip: false,
      userCanceled: false,
      uploadComplete: false,
      uploadInProgress: true
    },
    three: {
      reachedStep3: true,
      showSkip: false,
      userCanceled: false,
      uploadComplete: true,
      uploadInProgress: false
    }
  };

  private cancel = () => {
    this.setState(
      () => ({
        bytesUploadedSoFar: undefined,
        totalUploadInBytes: undefined,
        userCanceled: true
      }),
      () => {
        if (this.xhr) {
          this.xhr.abort();
        }
      }
    );
  };

  public constructor() {
    super();
    this.state = this.steps.one;
  }

  private uploadMessageDataFile = async ({
    newMessageDataFile: file,
    templateName
  }: {
    newMessageDataFile: File;
    templateName: string;
  }) => {
    const {
      client: { mutate },
      campaignId
    } = this.props;
    const {
      data: { presignMessageDataUpload: url }
    } = await mutate({
      mutation: PRESIGN,
      variables: {
        campaignId,
        fileName: file.name,
        fileType: file.type,
        templateName
      }
    });

    await new Promise((resolve, reject) => {
      this.xhr = new XMLHttpRequest();
      this.xhr.responseType = 'json';

      this.xhr.upload.addEventListener('progress', ({ loaded, total }) =>
        this.setState({
          percentComplete: Math.floor((100 * loaded) / total)
        })
      );

      this.xhr.addEventListener('readystatechange', e => {
        if (this.xhr && this.xhr.readyState === 4) {
          this.xhr.status === 200
            ? resolve(e)
            : reject(
                Error(
                  `Upload to ${url} failed. Server returned status code ${this.xhr.status} with status ${this.xhr.statusText}.`
                )
              );
        }
      });

      this.xhr.open('PUT', url);
      this.xhr.send(file);
    });
  };

  public render(
    { campaignId, client, templateName }: Props,
    {
      currentError,
      errorMessage,
      percentComplete,
      previewData,
      reachedStep3,
      showSkip,
      uploadComplete,
      uploadInProgress
    }: State
  ) {
    return (
      <Query query={GET_CAMPAIGN} variables={{ campaignId }}>
        {({ data, error, loading }) => {
          if (loading) {
            return null;
          }
          if (error) {
            return (
              <Alert error={error} type={Type.Error}>
                {error.message}
              </Alert>
            );
          }

          const { campaign } = data;
          const { phojis } = campaign;
          const template = campaign.templates.find(
            t => t.name === templateName
          );

          if (template.dataFile && !previewData) {
            presignMessageDataDownload(client.mutate, template.dataFile).then(
              result => {
                parse(result).then(previewData =>
                  this.setState({
                    previewData,
                    ...this.steps.three
                  })
                );
              }
            );
            return;
          }

          return (
            <div class={container}>
              {errorMessage && (
                <Alert error={currentError} type={Type.Error}>
                  {errorMessage}
                </Alert>
              )}
              <CloseLink href="/campaigns" />
              <div class={style.flexContainer}>
                <section class={main}>
                  <h1>{campaign.name}</h1>
                  <h2>Upload Your Template Message Data</h2>
                  <div class={uploadArea}>
                    <Upload
                      accept=".csv"
                      multiple={false}
                      onChange={async files => {
                        const [file] = files;
                        const previewData = await parse(file);
                        this.setState(
                          () => ({
                            previewData,
                            ...this.steps.two
                          }),
                          async () => {
                            try {
                              await this.uploadMessageDataFile({
                                newMessageDataFile: files[0],
                                templateName: template.name
                              });
                              await updateTemplate(
                                client.mutate,
                                campaignId,
                                template,
                                file.name
                              );

                              this.setState({
                                ...this.steps.three
                              });
                            } catch (err) {
                              const errState = this.state.userCanceled
                                ? {
                                    errorMessage: undefined,
                                    currentError: undefined
                                  }
                                : {
                                    errorMessage:
                                      'Error occurred during upload',
                                    currentError: err
                                  };

                              this.setState({
                                ...errState,
                                ...(reachedStep3
                                  ? this.steps.three
                                  : this.steps.one)
                              });
                            }
                          }
                        );
                      }}
                      percentComplete={percentComplete}
                      prompt={
                        reachedStep3 ? 'Need to change the file?' : undefined
                      }
                      promptLinkText={
                        reachedStep3 ? 'Click Here to Upload' : undefined
                      }
                      uploadInProgress={uploadInProgress}
                    />
                    {showSkip && !reachedStep3 && (
                      <a class={skipLink} href="/campaigns">
                        Skip Data Upload
                      </a>
                    )}
                  </div>
                </section>
                {phojis && (
                  <PhojiSidebar
                    className={style.phojis}
                    phojis={phojis.page.slice(0, 15)}
                  />
                )}
              </div>
              {uploadInProgress && (
                <button
                  class={`${button} ${cancel} ${formButton}`}
                  onClick={() => this.cancel()}
                >
                  Cancel
                </button>
              )}
              {uploadComplete && (
                <Link
                  href={templateBodyPath(campaignId, template.name)}
                  class={`${button} ${primaryButton} ${style.continueButton} ${formButton}`}
                >
                  Continue
                </Link>
              )}

              {previewData && (
                <section class={style.preview}>
                  <h1>
                    Message Data Preview{' '}
                    <span>
                      (column headers will be available as data merge fields)
                    </span>
                  </h1>
                  <MessageDataPreview {...previewData} />
                </section>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(TemplateData);
