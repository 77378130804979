let privateToken: string | null = null;
let tokenExpiredEvent: Event;
let tokenCreatedEvent: Event;

if (typeof window !== 'undefined') {
  tokenExpiredEvent = new Event('tokenExpired');
  tokenCreatedEvent = new Event('tokenCreated');
}

function parsedToken() {
  if (!privateToken) {
    return null;
  }
  const [header, payload] = privateToken.split('.');
  return {
    header: JSON.parse(atob(header)),
    payload: JSON.parse(atob(payload))
  };
}

export function deleteToken() {
  privateToken = null;
  dispatchEvent(tokenExpiredEvent);
}

export function getToken() {
  return privateToken;
}

export function setToken({ token }) {
  privateToken = token;
  dispatchEvent(tokenCreatedEvent);
  const parsed = parsedToken();
  if (!parsed) {
    return;
  }
  setTimeout(() => {
    deleteToken();
  }, parsed.payload.exp * 1000 - Date.now());
}

export function isTokenExpired() {
  const token = parsedToken();
  if (!token) {
    return true;
  }
  const { exp } = token.payload;
  const expirationDate = new Date(exp * 1000);
  return expirationDate <= new Date();
}

export function tokenPayload() {
  const token = parsedToken();
  return (token && token.payload) || null;
}
