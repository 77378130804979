import { h } from 'preact';
import { Link } from 'preact-router';

import { button, primaryButton } from '../shared-styles/buttons.css';

import style from './style.css';

interface Props {
  href: string;
}

export default ({ href }: Props) => (
  <Link class={`${button} ${primaryButton} ${style.editButton}`} href={href}>
    Edit
  </Link>
);
