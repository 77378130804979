import { h, Component } from 'preact';

interface Props {
  currentPlanState?: any;
}

/*
const UploadButton = ({
  accept,
  loading,
  multiple,
  tooltip,
  title,
  onChange
}: Props) => (
  <label
    class={`${label} ${loading ? disabled : ''}`}
    title={tooltip || 'Upload files'}
  >
  */
const PlanSummary = ({ currentPlanState }: Props) => {
  return currentPlanState ? (
    <div>
      <ul>
        <strong>Phojis left:</strong> {currentPlanState.phojisRemaining}
      </ul>
      <ul>
        <strong>Phojis used:</strong> {currentPlanState.phojisUsed}
      </ul>
      <ul>
        <strong>Total Phojis per plan cycle:</strong>{' '}
        {currentPlanState.planLimit}
      </ul>
      <ul>
        <strong>Plan cycle start date:</strong>{' '}
        {new Date(currentPlanState.startDate).toLocaleDateString()}
      </ul>
      <ul>
        <strong>Next plan cycle start date:</strong>{' '}
        {new Date(currentPlanState.nextStartDate).toLocaleDateString()}
      </ul>
    </div>
  ) : (
    <div />
  );
};

export default PlanSummary;
