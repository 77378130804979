import { h, Component } from 'preact';

import style from './style.css';
import {
  cancel,
  container,
  formButton,
  main,
  skipLink,
  uploadArea
} from '../shared-styles/main.css';
import {
  button,
  primaryButton
} from '../../../components/shared-styles/buttons.css';

import { baseStaticUrl } from '../../../shared/constants';
import { GET_CAMPAIGN } from '../../../queries/campaign';
import Alert, { Type } from '../../../components/alert';
import { CloseLink } from '../../../components/close';
import Upload from '../../../components/upload';
import { newTemplatePath } from '../../../shared/paths';
import { Query, withApollo } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { Link } from 'preact-router';
import {
  addNewPhojiToCampaignCache,
  GET_CAMPAIGN_PHOJIS,
  PRESIGN_PHOJI_UPLOAD
} from '../../../queries/phoji';

interface Props {
  client: ApolloClient;
  campaignId: string;
}

interface State {
  bytesUploadedSoFar?: number;
  currentError?: Error;
  errorMessage?: string;
  lastLoadedBytes?: number;
  numberOfFilesLeft?: number;
  reachedStep3: boolean;
  showSkip: boolean;
  totalUploadInBytes?: number;
  uploadComplete: boolean;
  uploadInProgress: boolean;
  userCanceled: boolean;
}

class Index extends Component<Props, State> {
  private xhr: XMLHttpRequest | undefined;
  private uploadQueue: File[] = [];
  private steps = {
    one: {
      bytesUploadedSoFar: undefined,
      showSkip: true,
      totalUploadInBytes: undefined,
      uploadComplete: false,
      uploadInProgress: false
    },
    two: {
      bytesUploadedSoFar: 0,
      showSkip: false,
      userCanceled: false,
      uploadComplete: false,
      uploadInProgress: true
    },
    three: {
      bytesUploadedSoFar: undefined,
      reachedStep3: true,
      showSkip: false,
      totalUploadInBytes: undefined,
      userCanceled: false,
      uploadComplete: true,
      uploadInProgress: false
    }
  };

  public constructor() {
    super();
    this.state = this.steps.one;
  }

  private cancel = () => {
    this.setState(
      () => ({
        bytesUploadedSoFar: undefined,
        totalUploadInBytes: undefined,
        userCanceled: true
      }),
      () => {
        this.uploadQueue = [];
        if (this.xhr) {
          this.xhr.abort();
        }
      }
    );
  };

  private uploadPhoji = async (file, url) =>
    new Promise((resolve, reject) => {
      this.xhr = new XMLHttpRequest();
      this.xhr.responseType = 'json';

      /*
        `loaded` is the total number of bytes for this upload. We allow
        for multiple uploads and care about overall bytes so we have to
        keep track of the previous loaded value.
      */
      this.xhr.upload.addEventListener('progress', ({ loaded, total }) => {
        const { bytesUploadedSoFar = 0, lastLoadedBytes = 0 } = this.state;
        this.setState(() => ({
          bytesUploadedSoFar: bytesUploadedSoFar + loaded - lastLoadedBytes,
          lastLoadedBytes: loaded === total ? 0 : loaded
        }));
      });

      this.xhr.addEventListener('readystatechange', e => {
        if (this.xhr && this.xhr.readyState === 4) {
          this.xhr.status === 200
            ? resolve(e)
            : reject(
                Error(
                  `Upload to ${url} failed. Server returned status code ${this.xhr.status} with status ${this.xhr.statusText}.`
                )
              );
        }
      });
      this.xhr.open('PUT', url);
      this.xhr.send(file);
    });

  private upload = async (files: File[]) => {
    this.uploadQueue = Array.from(files).reverse();

    const {
      campaignId,
      client,
      client: { mutate }
    } = this.props;

    while (this.uploadQueue.length) {
      const file = this.uploadQueue.pop();

      this.setState({
        numberOfFilesLeft: this.uploadQueue.length + 1
      });

      const {
        data: { presignPhojiUpload }
      } = await mutate({
        mutation: PRESIGN_PHOJI_UPLOAD,
        variables: {
          fileName: file.name,
          fileType: file.type,
          campaignId
        }
      });

      const { url, expectedPhoji: phoji } = presignPhojiUpload;
      await this.uploadPhoji(file, url);
      addNewPhojiToCampaignCache(phoji, client, campaignId);
    }
  };

  public render(
    { campaignId }: Props,
    {
      bytesUploadedSoFar,
      currentError,
      errorMessage,
      numberOfFilesLeft,
      reachedStep3,
      showSkip,
      totalUploadInBytes,
      uploadComplete,
      uploadInProgress
    }: State
  ) {
    return (
      <Query query={GET_CAMPAIGN} variables={{ campaignId }}>
        {({ data, error, loading }) => {
          if (loading) {
            return null;
          }
          if (error) {
            return <Alert type={Type.Error}>{error.message}</Alert>;
          }

          const { campaign } = data;

          return (
            <div class={container}>
              {!this.state.userCanceled && currentError && errorMessage ? (
                <Alert error={currentError} type={Type.Error}>
                  {errorMessage}
                </Alert>
              ) : null}

              <CloseLink href="/campaigns" />
              <div class={main}>
                <h1>{campaign.name}</h1>
                <h2>Upload Images</h2>
                <div class={uploadArea}>
                  <Upload
                    accept="image/*"
                    multiple={true}
                    onChange={files => {
                      this.setState(
                        () => ({
                          ...this.steps.two,
                          totalUploadInBytes: files.reduce(
                            (sum, { size }) => sum + size,
                            0
                          )
                        }),
                        async () => {
                          try {
                            await this.upload(files);
                            this.setState({ ...this.steps.three });
                          } catch (err) {
                            const errState = this.state.userCanceled
                              ? {
                                  errorMessage: undefined,
                                  currentError: undefined
                                }
                              : {
                                  errorMessage: 'Error occurred during upload',
                                  currentError: err
                                };

                            this.setState({
                              ...errState,
                              ...(reachedStep3
                                ? this.steps.three
                                : this.steps.one)
                            });
                          }
                        }
                      );
                    }}
                    percentComplete={Math.max(
                      0,
                      ((bytesUploadedSoFar || 0) / (totalUploadInBytes || 1)) *
                        100
                    )}
                    uploadInProgress={uploadInProgress}
                  />
                  {showSkip && !reachedStep3 && (
                    <a class={skipLink} href="/campaigns">
                      Skip Image Uploads
                    </a>
                  )}
                </div>
                {uploadComplete && (
                  <div class={style.complete}>
                    Upload Complete, you can upload more or continue
                  </div>
                )}
              </div>
              {uploadInProgress && (
                <button
                  class={`${button} ${cancel} ${formButton}`}
                  onClick={() => this.cancel()}
                >
                  Cancel
                </button>
              )}
              {uploadComplete && (
                <Link
                  href={newTemplatePath(campaignId)}
                  class={`${button} ${primaryButton} ${formButton}`}
                >
                  Continue
                </Link>
              )}
              <Query query={GET_CAMPAIGN_PHOJIS} variables={{ campaignId }}>
                {({ data, error, loading }) => {
                  if (loading) {
                    return null;
                  }
                  if (error) {
                    return <Alert type={Type.Error}>{error.message}</Alert>;
                  }

                  const { phojis } = data;

                  return (
                    <ul class={style.phojiList}>
                      {uploadInProgress &&
                        Array.from({ length: numberOfFilesLeft || 0 }).map(
                          (_, i) => {
                            return (
                              <li class="animated fadeIn">
                                <svg class={style.placeholder} />
                              </li>
                            );
                          }
                        )}
                      {phojis.page.map(({ name }) => (
                        <li class="animated fadeIn">
                          <img
                            class={style.placeholder}
                            src={`${baseStaticUrl}/${name}?s=100`}
                          />
                        </li>
                      ))}
                    </ul>
                  );
                }}
              </Query>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(Index);
