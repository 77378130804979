import { h, Component } from 'preact';
import ApolloClient from 'apollo-client';
import { route } from 'preact-router';
import { Query, withApollo } from 'react-apollo';

import { CloseLink } from '../../../../components/close';
import {
  cancel,
  container,
  main,
  skipLink
} from '../../shared-styles/main.css';
import {
  button,
  primaryButton
} from '../../../../components/shared-styles/buttons.css';
import { GET_CAMPAIGN } from '../../../../queries/campaign';
import Alert, { Type } from '../../../../components/alert';
import { updateTemplate } from '../../../../queries/template';
import MergeFieldsSidebar from '../../../../components/merge-fields-sidebar';
import PhojiSidebar from '../../../../components/phoji-sidebar';
import { templateCustomizePath } from '../../../../shared/paths';

import style from './style.css';
import { parse, PreviewData } from '../../../../shared/message-data';
import { presignMessageDataDownload } from '../../../../queries/message-data';

interface Props {
  campaignId: string;
  client: ApolloClient;
  templateName: string;
}

interface State {
  body?: string;
  currentError?: Error;
  errorMessage?: string;
  previewData?: PreviewData;
  reachedStep3: boolean;
  showSkip: boolean;
  template: object;
}

class TemplateBody extends Component<Props, State> {
  public render(
    { campaignId, client, templateName }: Props,
    { body, currentError, errorMessage, previewData }: State
  ) {
    return (
      <Query asyncMode query={GET_CAMPAIGN} variables={{ campaignId }}>
        {({ data, error, loading }) => {
          if (loading) {
            return null;
          }
          if (error) {
            return (
              <Alert error={error} type={Type.Error}>
                {error.message}
              </Alert>
            );
          }

          const { campaign } = data;
          const { phojis } = campaign;
          const template = campaign.templates.find(
            t => t.name === templateName
          );

          if (template.body && body === undefined) {
            this.setState({ body: template.body });
          }

          if (!previewData) {
            // async/await does not work here, page renders blank
            presignMessageDataDownload(client.mutate, template.dataFile).then(
              result => {
                parse(result).then(previewData =>
                  this.setState({ previewData })
                );
              }
            );
            return;
          }
          const { fields } = previewData;
          return (
            <div class={container}>
              {errorMessage && (
                <Alert error={currentError} type={Type.Error}>
                  {errorMessage}
                </Alert>
              )}
              <CloseLink href="/campaigns" />
              <div class={style.flexContainer}>
                <section class={main}>
                  <h1>
                    {campaign.name} - <em>{template.name}</em>
                  </h1>
                  <h2>Build Your Message Template</h2>
                  <textarea
                    class={style.templateMessageBody}
                    onInput={e => {
                      this.setState({
                        body: (e.target as HTMLTextAreaElement).value
                      });
                    }}
                    placeholder="Start typing your message here. Drag in your Merge Fields as you type to add the data from your Message Data Upload."
                    value={body}
                  ></textarea>
                </section>

                <div class={style.rightColumn}>
                  <MergeFieldsSidebar draggable={true} fields={fields} />
                  {phojis && (
                    <PhojiSidebar
                      draggable={true}
                      phojis={phojis.page.slice(0, 15)}
                    />
                  )}
                </div>
              </div>
              <div class={style.buttonContainer}>
                <div class={style.flexFill}>
                  {!body && (
                    <a class={`${skipLink}`} href="/campaigns">
                      Skip Message Building
                    </a>
                  )}
                </div>

                <button
                  class={`${button} ${body ? primaryButton : cancel} ${
                    style.preview
                  }`}
                  disabled={!body}
                  onClick={async () => {
                    template.body = body;
                    await updateTemplate(client.mutate, campaignId, template);
                    route(templateCustomizePath(campaignId, templateName));
                  }}
                >
                  Preview
                </button>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(TemplateBody);
