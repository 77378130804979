import { h, Component } from 'preact';
import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import Downshift from 'downshift/preact';

import debounce from '../../shared/debounce';

import { formGroup } from '../../components/shared-styles/form.css';

import style from './style.css';

const TYPEAHEAD = gql`
  query Typeahead($prefix: String!) {
    labelTypeahead(prefix: $prefix) {
      value
    }
  }
`;

interface Props {
  client: ApolloClient;
  className?: string;
  onLabelsChanged: (labels?: [string]) => void;
  labels?: [string];
}

interface State {
  labels?: [string];
  typeahead?: [string];
}

export default class LabelTypeahead extends Component<Props, State> {
  public constructor(props) {
    super(props);

    this.state = {
      labels: props.labels
    };
  }

  private queryTypeahead = debounce(async (...[client, prefix]) => {
    const { data } = await client.query({
      query: TYPEAHEAD,
      variables: { prefix }
    });
    this.setState({
      typeahead: data.labelTypeahead
    });
  }, 500);

  private readTypeaheadFromCache = (client, prefix) => {
    // Unfortunately readQuery throws instead of returning undefined
    // https://github.com/apollographql/apollo-feature-requests/issues/1
    let result = undefined;
    try {
      result = client.readQuery({
        query: TYPEAHEAD,
        variables: { prefix }
      });
    } catch {
      // Intentionally left empty
    }
    return result;
  };

  private prefixHasEmptyResult = (client, prefix) => {
    for (let i = 0; i < prefix.length; i++) {
      const result = this.readTypeaheadFromCache(
        client,
        prefix.substring(0, i)
      );
      if (result && result.labelTypeahead.length === 0) {
        return true;
      }
    }
    return false;
  };

  public render({ className, client, onLabelsChanged }, { labels, typeahead }) {
    return (
      <Downshift
        itemToString={item => (item && item.value) || ''}
        onChange={(selection, stateAndHelpers) => {
          if (!selection) {
            return;
          }
          const { value } = selection;
          this.setState(
            () => {
              return {
                labels: labels ? [...new Set([value, ...labels])] : [value]
              };
            },
            () => {
              onLabelsChanged(this.state.labels);
              stateAndHelpers.reset({
                inputValue: null,
                highlightedIndex: null,
                selectedItem: null
              });
            }
          );
        }}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          reset,
          selectedItem
        }) => (
          <div class={`${formGroup} ${className || ''}`}>
            <label {...getLabelProps()}>Labels</label>
            <input
              {...getInputProps({
                disabled: (labels || []).length === 10,
                maxLength: 50,
                onChange: ({ target }) => {
                  this.setState({ typeahead: [] });
                  const prefix = target.value;
                  const result = this.readTypeaheadFromCache(client, prefix);

                  // If we're in the cache skip the debounce call
                  if (result) {
                    this.setState({
                      typeahead: result.labelTypeahead
                    });
                  } else if (!this.prefixHasEmptyResult(client, prefix)) {
                    this.queryTypeahead(client, prefix);
                  }
                },
                onKeyPress: event => {
                  if (event.key == 'Enter' && inputValue) {
                    event.preventDefault();
                    this.setState(
                      () => ({
                        labels: labels
                          ? [...new Set([inputValue, ...labels])]
                          : [inputValue]
                      }),
                      () => onLabelsChanged(this.state.labels)
                    );
                    reset({
                      inputValue: null,
                      highlightedIndex: null,
                      selectedItem: null
                    });
                  }
                },
                placeholder:
                  (labels || []).length === 10 ? 'Maximum of 10 labels' : null
              })}
            />
            <ul
              {...getMenuProps({
                class: style.typeaheadMenu
              })}
            >
              {isOpen
                ? (typeahead || []).map((item, index) => (
                    <li
                      {...getItemProps({
                        key: item.value,
                        index,
                        item,
                        style: {
                          backgroundColor:
                            highlightedIndex === index
                              ? 'var(--secondary-blue-white)'
                              : 'var(--secondary-white)',
                          fontWeight: selectedItem === item ? 'bold' : 'normal'
                        }
                      })}
                    >
                      {item.value}
                    </li>
                  ))
                : null}
            </ul>
            <ul class={style.existingLabels}>
              {(labels || []).map(l => (
                <li>
                  {l}
                  <button
                    class={style.deleteLabelButton}
                    onClick={() => {
                      this.setState(
                        () => ({
                          labels: labels.filter(el => el !== l)
                        }),
                        () => onLabelsChanged(this.state.labels)
                      );
                    }}
                    type="button"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Downshift>
    );
  }
}
