import { h, Component } from 'preact';
import { route } from 'preact-router';
import { Mutation, Query, withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import {
  formGroup,
  required
} from '../../../../components/shared-styles/form.css';
import { CloseLink } from '../../../../components/close';
import { container, main } from '../../shared-styles/main.css';
import {
  button,
  primaryButton
} from '../../../../components/shared-styles/buttons.css';
import { GET_CAMPAIGN } from '../../../../queries/campaign';
import { TemplateType } from '../../../../shared/constants';
import Alert, { Type } from '../../../../components/alert';
import { TEMPLATE_FIELDS } from '../../../../queries/template';

import { createButton } from './style.css';
import ApolloClient from 'apollo-client';
import Labels from '../../../../components/labels';
import { templateDataPath } from '../../../../shared/paths';

const ADD_TEMPLATE_TO_CAMPAIGN = gql`
  mutation AddTemplateToCampaign(
    $campaignId: String!
    $name: String!
    $type: TemplateType!
  ) {
    addTemplateToCampaign(campaignId: $campaignId, name: $name, type: $type) {
      ...templateFields
    }
  }
  ${TEMPLATE_FIELDS}
`;

interface Props {
  client: ApolloClient;
  campaignId: string;
}

interface State {
  currentError?: Error;
  errorMessage?: string;
  labels?: [string];
  name?: string;
  type: TemplateType;
}

class NewTemplateStep1 extends Component<Props, State> {
  private handleSubmit = (e, mutation) => {
    e.preventDefault();
    mutation();
  };

  public render(
    { campaignId, client },
    { currentError, errorMessage, name, type }
  ) {
    return (
      <Query query={GET_CAMPAIGN} variables={{ campaignId }}>
        {({ data, error, loading }) => {
          if (loading) {
            return null;
          }
          if (error) {
            return (
              <Alert error={error} type={Type.Error}>
                {error.message}
              </Alert>
            );
          }

          const { campaign } = data;

          return (
            <div class={container}>
              {errorMessage && (
                <Alert error={currentError} type={Type.Error}>
                  {errorMessage}
                </Alert>
              )}
              <CloseLink href="/campaigns" />
              <div class={main}>
                <h1>{campaign.name}</h1>
                <h2>
                  {campaign.templates.length
                    ? 'Create a New Template'
                    : 'Create Your First Template'}
                </h2>
                <Mutation
                  mutation={ADD_TEMPLATE_TO_CAMPAIGN}
                  onCompleted={() => route(templateDataPath(campaignId, name))}
                  onError={err =>
                    this.setState({
                      currentError: err,
                      errorMessage: 'Template creation failed'
                    })
                  }
                  update={(store, { data: { addTemplateToCampaign } }) => {
                    const { campaign } = store.readQuery({
                      query: GET_CAMPAIGN,
                      variables: { campaignId }
                    });
                    campaign.templates.push(addTemplateToCampaign);
                  }}
                  variables={{ campaignId, name, type }}
                >
                  {mutation => (
                    <form
                      method="post"
                      onSubmit={e => this.handleSubmit(e, mutation)}
                    >
                      <div class={formGroup}>
                        <label class={required}>
                          <span>Name</span>
                          <input
                            autofocus
                            name="name"
                            onInput={({ target }) =>
                              this.setState({
                                name: (target as HTMLInputElement).value
                              })
                            }
                            placeholder="Enter a template name"
                            required
                            type="text"
                            value={name}
                          />
                        </label>
                      </div>
                      <div class={formGroup}>
                        <label class={required}>
                          <span>Template Type</span>
                          <select
                            onChange={({ target }) =>
                              this.setState({
                                type: (target as HTMLSelectElement).value
                              })
                            }
                            name="type"
                            placeholder="Select One"
                            required
                          >
                            <option value="">Select One</option>
                            <option value={TemplateType.Email}>Email</option>
                            <option value={TemplateType.Mobile}>
                              Mobile push notification
                            </option>
                            <option value={TemplateType.Web}>
                              Web message
                            </option>
                          </select>
                        </label>
                      </div>
                      <Labels
                        client={client}
                        onLabelsChanged={labels =>
                          this.setState(() => ({ labels }))
                        }
                      />
                      {errorMessage && <div>{errorMessage}</div>}
                      <button
                        class={`${button} ${primaryButton} ${createButton}`}
                        disabled={!name || !type}
                        type="submit"
                      >
                        Create
                      </button>
                    </form>
                  )}
                </Mutation>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(NewTemplateStep1);
